
import { Component } from "react";
import { CustomDialog, CustomDialogTitle } from "./CustomDialog"
import * as React from 'react';
import styles from "./../CSSModules/Popups/ReportBug.module.css";
import { DialogContent, InputBase, Slide, TextareaAutosize } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class ReportBug extends Component {

    constructor() {
        super();
        this.state = {
            name: "ReportBug",
            open: true, 
            email: "", 
            bugMessage: ""
        };
    }

    componentWillUnmount() { 
        this.setState({email: "", bugMessage: ""});        
    }

    focusInput = (e) => window.unity.SendMessage('bloodbridge', "disablekeycapture", "");

    blurInput = (e) => window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");    

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.onPopupClose();
    };

    async sendBug() {
        const response = await fetch(`api/Account/ReportBug`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({email: this.state.email, message: this.state.bugMessage})
        });

        const data = await response.json();

        if (response.status != 200) {
            console.error('HTTP response code status->' + response.status + ' message->' + data);
            return;
        }

        this.handleClose();
    }

    render() {

        var { open, email, bugMessage } = this.state;

        return (
            <CustomDialog className={styles.promoteStoreDialog}
                maxWidth={"750px"}                
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-describedby="alert-dialog-slide-description">
                <CustomDialogTitle>{"Report a bug"}</CustomDialogTitle>
                <DialogContent>
                    <div className={styles.promoteLabel}>
                        Please enter your issue in the below field along with your email address.
                    </div>
                    <InputBase
                        placeholder="Email Address"
                        sx={{ fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.bugEmailTextField}
                        type="text"
                        variant="filled"
                        fullWidth                       
                        size="small"
                        onFocus={(e) => this.focusInput(e)}
                        onBlur={(e) => this.blurInput(e)}
                        onChange={(e) => { this.setState({email: e.target.value}) }}
                        value={email} />
                    <TextareaAutosize
                        minRows={2}
                        maxRows={2}
                        className={styles.bugMessageTextField}
                        aria-label="message"
                        placeholder="Please describe the issues you are facing"
                        onFocus={(e) => this.focusInput(e)}
                        onBlur={(e) => this.blurInput(e)}
                        onChange={(e) => { this.setState({bugMessage: e.target.value}) }}
                        value={bugMessage}
                    />
                    <div className={styles.sendBugButton} onClick={() => {this.sendBug()}}>Send</div>

                </DialogContent>
            </CustomDialog>
        )
    }
}