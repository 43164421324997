import { Checkbox, ImageList, ImageListItem} from "@mui/material";
import { Component } from "react";
import { colorData } from "../../lib/colorData";
import { floorImageData } from "../../lib/floorImageData";
import styles from "./../CSSModules/CustomiseView.module.css";
import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center',
        marginTop: '200px!important'
    },
}));

export class CustomiseView extends Component {

    constructor() {
        super();
        this.state = {
            name: "Customisation",
            hide: false,            
            loading: false,
            floorColorId: 1,
            floorColorIdKey: "floorColorId",
            wallColor: "",           
            accentsColor: "",
            accentsColorKey: "accentsColor",
            tabValue: 0
        };
    }

    handleChange = (event, newValue) => {
        this.setState({tabValue: newValue});
    };

    componentDidMount() {
        this.setState({ 
            floorColorId: this.props.unityUser.floorMaterialId,
            wallColor: this.props.unityUser.wallColor,
            accentsColor: this.props.unityUser.accentsColor
        });
    }

    componentWillUnmount() {        
        
    }

    setWallColor(colorId) {
        if(colorId === this.state.wallColor) {
            return ;
        }

        let user = this.props.unityUser;
        user.wallColor = colorId;

        this.setState({wallColor: colorId});
        this.props.onUnityUserDataChange(user);
    }

    setAccentColor(colorId) {
        if(colorId === this.state.accentsColor) {
            return ;
        }

        let user = this.props.unityUser;
        user.accentsColor = colorId;

        this.setState({accentsColor: colorId});
        this.props.onUnityUserDataChange(user);
    }

    setFloorColor(materialId) {
        if(materialId === this.state.floorColorId) {
            return ;
        }

        let user = this.props.unityUser;
        user.floorMaterialId = materialId;

        this.setState({floorColorId: materialId});
        this.props.onUnityUserDataChange(user);
    }

    renderWallImageList() {
        var { wallColor } = this.state;
        return (
        <div>
            <ImageList sx={{ width: 270, maxHeight: 'calc(100vh - 250px)', height: 'auto', minHeight: '480px' }} cols={4} rowHeight={60}>
                    {colorData.map((item) => (
                        <ImageListItem key={item.colorId} >                            
                            <div style={{backgroundColor: item.colorId}} 
                                onClick={()=> this.setWallColor(item.colorId)} 
                                className={styles.colorContainer}>
                                    <Checkbox checked={wallColor === item.colorId} 
                                    className={wallColor === item.colorId ? styles.colorCheckbox : styles.colorCheckboxHide}
                                    size="small" sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }}/>
                            </div>                            
                        </ImageListItem>
                    ))}
                </ImageList>

        </div>);
    }

    renderAccentsImageList() {
        var { accentsColor } = this.state;
        return (
        <div>
            <ImageList sx={{ width: 270, maxHeight: 'calc(100vh - 250px)', height: 'auto', minHeight: '480px' }} cols={4} rowHeight={60}>
                    {colorData.map((item) => (
                        <ImageListItem key={item.colorId} >                            
                            <div style={{backgroundColor: item.colorId}} 
                                onClick={()=> this.setAccentColor(item.colorId)} 
                                className={styles.colorContainer}>
                                    <Checkbox checked={accentsColor === item.colorId} 
                                    className={accentsColor === item.colorId ? styles.colorCheckbox : styles.colorCheckboxHide}  
                                    size="small" sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }}/>
                            </div>                            
                        </ImageListItem>
                    ))}
                </ImageList>

        </div>);
    }

    renderFloorImageList() {
        var { floorColorId } = this.state;

        return (
        <div>
            <ImageList sx={{ width: 270, height: '70px' }} cols={4} rowHeight={60}>
                    {floorImageData.map((item) => (
                        <ImageListItem key={item.id} >                            
                            <div onClick={()=> this.setFloorColor(item.id)} className={styles.colorContainer}>                                        
                                    <img className={styles.colorImgItem}
                                        src={`${item.icon}?w=55&h=55`}
                                        alt={item.icon}
                                        name={item.icon}
                                        loading="lazy"/>
                                    <Checkbox checked={floorColorId === item.id} 
                                        className={floorColorId === item.id ? styles.colorCheckbox : styles.colorCheckboxHide} 
                                        size="small" 
                                        sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }}/>
                            </div>
                            
                        </ImageListItem>
                    ))}
                </ImageList>

        </div>);
    }

    render() {
        var { hide, tabValue } = this.state;
        var tutorialData = this.props.tutorialData;

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            :
            (<div>
                <Tabs className={styles.customiseTabs}>
                    <TabList className={styles.customiseTabList}>
                        <Tab className={styles.customiseTab} selectedClassName={styles.customiseTabSelected}>Flooring</Tab>
                        <Tab className={styles.customiseTab} selectedClassName={styles.customiseTabSelected}>Wall Colour</Tab>
                        <Tab className={styles.customiseTab} selectedClassName={styles.customiseTabSelected}>Store Accents</Tab>
                    </TabList>

                    <TabPanel className={styles.customiseTabPanel}>
                        <section className={styles.floorColorSection}>
                            {this.renderFloorImageList()}
                        </section>
                    </TabPanel>
                    <TabPanel className={styles.customiseTabPanel}>
                        <section className={styles.wallColorSection}>
                            {this.renderWallImageList()}
                        </section>
                    </TabPanel>
                    <TabPanel className={styles.customiseTabPanel}>
                        <section className={styles.accentColorSection}>
                            {this.renderAccentsImageList()}
                        </section>
                    </TabPanel>
                </Tabs>
            </div>);

        return (
            <div className={hide ? styles.customisationHide : styles.customisation}>
                <h4 className={styles.customiseTitle}>Customise My Store</h4>
                <BootstrapTooltip
                    key={"mySore"}
                    open={tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 1}
                    placement="right"
                    title={"Choose your store colour"}>
                    {contents}
                </BootstrapTooltip>
            </div>
        )
    }
}