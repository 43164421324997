import { IconButton, InputBase } from "@mui/material";
import { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export class StoreLogoStep extends Component {

  handleChangeFile = (file) => {
    console.log(file.name);
    this.props.handleChangeLogoFile(file);
  };

  getFileNameFromUrl() {
    if (!this.props.storeLogoUrl)
      return null;

    var filename = this.props.storeLogoUrl.split('/').pop();    
    if (filename.toLowerCase().indexOf(".jpg") >-1 || filename.toLowerCase().indexOf(".jpeg")>-1 || filename.toLowerCase().indexOf(".png")>-1)
      return filename;

    return null;
  }

  renderFileDropZone = () => {
    let styles = this.props.styles;

    return <div className={styles.setupStoreFileDropZone}>
      <div className={styles.setupStorefileUploadIconContainer}>
        <img className={styles.fileUploadIcon}
          src="./images/icons/Download_black.png"
          alt="UploadIcon" />
      </div>
      <h4 className={styles.fileUploadTitle}>Select a file to upload</h4>
      <div>or drag and drop it here</div>
    </div>;
  }

  render() {
    let styles = this.props.styles;
    const fileTypes = ["JPG", "PNG", "JPEG"];
    const element = this.renderFileDropZone();

    if (this.props.currentStep !== 2) {
      return null
    }
    return (
      <div className={styles.setupStoreStep}>
        <h2 className={styles.storeNameStepTitle}>Upload your logo</h2>
        <div className={styles.storeNameStepDescription}>
          You can change it anytime.
          <br />
          Tip: Upload a logo with a transparent backdrop for the best result.
        </div>
        {
          this.props.storeLogoFile || this.getFileNameFromUrl() ?
            <div className={styles.setupStoreFileNameContainer}>
              <div>{this.props.storeLogoFile ? this.props.storeLogoFile.name : this.getFileNameFromUrl()}</div>
              <IconButton aria-label="Delete" sx={{ color: '#6219d3', ml: "auto" }} onClick={()=>{this.props.handleChangeLogoFile(null)}}>
                <DeleteOutlineIcon />
              </IconButton>
            </div>
            :
            <div className={styles.storeLogoStepContainer}>
              <FileUploader
                handleChange={(e) => this.handleChangeFile(e)}
                name="file"
                children={element}
                hoverTitle="Drop Here"
                types={fileTypes} />
            </div>
        }
        <div className={styles.storeLogoStepInput}>
          <div>Or upload from a URL</div>
          <InputBase
            id="storeLogoUrl"
            name="storeLogoUrl"
            placeholder="Enter image URL"
            sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
            className={styles.setupStoreLogoTextField}
            type="text"
            variant="filled"
            fullWidth
            onChange={this.props.handleChange}
            value={this.props.storeLogoUrl} />
          
        </div>
        <div className={styles.setupStoreProceedButton} onClick={this.props.handleNext}>Next</div>

      </div>
    );
  }

  /*<div className={styles.setupStoreProceedButton} onClick={()=>{}}>Upload</div>
  <input
              name="storeLogoUrl"
              type="text"
              placeholder="Enter your store logo"
              value={this.props.storeLogoUrl}
              onChange={this.props.handleChange}
              /> */
}