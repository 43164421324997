export class LoginUserModel {
    constructor(
        email,
        password,
        isRememberMe) {

        this.email = email;
        this.password = password;
        this.isRememberMe = isRememberMe;
    }
}