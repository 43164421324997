export class RegisterUserModel{
    constructor(        
        firstName, 
        lastName, 
        phoneNumber, 
        email, 
        password, 
        confirmPassword, 
        isAgreeTCaPP, 
        isReceiveUpdates ) {
      
      this.lastName = lastName;
      this.firstName = firstName;
      this.password = password;
      this.phoneNumber = phoneNumber;
      this.email = email;
      this.confirmPassword = confirmPassword;
      this.isAgreeTCaPP = isAgreeTCaPP;
      this.isReceiveUpdates = isReceiveUpdates;
    }
  }