import { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import styles from "./../CSSModules/MyProductsView.module.scss";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import ClearOutlinedIcon from '@mui/icons-material/Clear';
import { CustomDialog, CustomDialogTitle } from "../Popups/CustomDialog";
import { CircularProgress, DialogActions, DialogContent, Slide } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center',
        
    },
}));
import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class MyProductsView extends Component {

    constructor() {
        super();
        this.state = {
            name: "My Products",
            hide: false,
            loading: true,
            loadingDialog: false,
            searchValue: '',
            dragProduct: null,
            productsKey: "products",
            isDialogShow: false,
            isCutoutTool: false,
            selectedIndex: -1,
            isAddProducts: false,
            isFilesLoaded: false,
            isProductsDialogShow: false,
            newFiles: [],
            newProducts: [],
            products: [
                { id: 0, thumbnailUrl: "", productUrl: "", name: "" }
            ]
        };

        window.addEventListener('mouseup', this.clickUp);
    }

    componentDidMount() {
        this.getMyProducts();
    }

    async getMyProducts() {
        this.setState({ loading: true });
        const response = await fetch('api/Product/GetMyProducts');
        const data = await response.json();

        if (response.status != 200) {
            console.error(data);
            this.setState({ loading: false });
        }
        else {
            if (data && data.length) {
                data.push({ id: 0, thumbnailUrl: "", productUrl: "", name: "" });
                this.setState({ products: data, loading: false });
            }
        }

        this.setState({ loading: false });
    }

    componentWillUnmount() {

    }

    clickUp = (e) => {
        if (window.unityMounted) {
            window.unity.SendMessage('bloodbridge', "itemdragend", "");

            if (this.state.dragProduct) {
                this.setState({ dragProduct: null });
                this.setState({ hide: false });
            }
        }
    }

    focusInput(e) {
        window.unity.SendMessage('bloodbridge', "disablekeycapture", "");
    }

    blurInput(e, item, i, isNew = false) {
        window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");

        if (!isNew && (item.productUrl || item.name)) {
            this.uploadProductToServer(null, item, i);
        }
    }

    setValue(value) {
        this.setState({ searchValue: value });
    }

    uploadProduct(e, item, index) {
        let file = document.getElementById("uploadproduct" + index).files[0];
        if (file) {
            if (file.size > 10485760) {
                alert("File is too big!");
                filesValid = false;
                console.log("Files over 10mb size");
                return;
            }
            else {
                this.uploadProductToServer(file, item, index);
            }
        }
    }

    setProductUrl(value, item, i) {
        let products = this.state.products;
        item.productUrl = value;
        products[i] = item;

        this.setState({ products: products });
    }

    setProductName(value, item, i) {
        let products = this.state.products;
        item.name = value;
        products[i] = item;

        this.setState({ products: products });
    }

    setNewProductName(value, item, i) {
        let products = this.state.newProducts;
        item.name = value;
        products[i] = item;

        this.setState({ newProducts: products });
    }

    setNewProductUrl(value, item, i) {
        let products = this.state.newProducts;
        item.productUrl = value;
        products[i] = item;

        this.setState({ newProducts: products });
    }

    deleteProduct(item, i) {
        let products = this.state.products;
        products.splice(i, 1);

        if (item.id > 0) {
            this.deleteProductFromServer(item, i);
        }

        this.setState({ products: products });
    }

    deleteNewProduct(item, i) {
        let products = this.state.newProducts;
        products.splice(i, 1);

        this.setState({ newProducts: products });
    }


    async deleteProductFromServer(item, index) {
        await fetch(`api/Product/DeleteProduct/${item.id}`, { method: 'DELETE' });
    }

    addProducts() {
        this.setState({isAddProducts: true});
       // let products = this.state.products;
       // products.push({ id: 0, thumbnailUrl: "", productUrl: "", name: "" });
       // this.setState({ products: products });
    }

    addProduct() {

        //call server and add product and new for add

       let products = this.state.products;
       products.push({ id: 0, thumbnailUrl: "", productUrl: "", name: "" });
       this.setState({ products: products });
    }

    closeAddProducts(e) {
        e.preventDefault();
        
        this.state.newProducts.forEach(item => {
            URL.revokeObjectURL(item.thumbnailUrl);
        });

        this.setState({
            isAddProducts: false, 
            isFilesLoaded: false, 
            newFiles: [], 
            newProducts: []});
    }
    
    handleChangeFile = (files) => {
        if (files.length && files.length > 0) {
            let newProducts = Array.from(files).map(file => {
                return { id: 0, thumbnailUrl: URL.createObjectURL(file), productUrl: "", name: "" };
            });
            //URL.createObjectURL(file);
            this.setState({ newFiles: files, newProducts: newProducts, isFilesLoaded: true });
        }
    };

    /*DnD */
    onStart(item) {
        if (this.state.dragProduct != null || !item.thumbnailUrl) {
            return;
        }

        this.setState({ hide: true });

        this.setState({ dragProduct: item });
        console.log("start Product Drag -> " + item.thumbnailUrl);

        if (window.unityMounted) {
            window.unity.SendMessage('bloodbridge', "enablekeycapture", "");
            let product = { productVisualKey: item.thumbnailUrl, productURL: item.productUrl };
            window.unity.SendMessage('bloodbridge', "productdragstart", JSON.stringify(product));
        }

        document.getElementById("unity-canvas").focus();
        document.getElementById("unity-canvas").click();
    };

    handleUploadClose = () => {
        this.setState({ isDialogShow: false, isProductsDialogShow: false, selectedIndex: -1, isCutoutTool: false });
    }

    continueUpload(isTool) {
        this.setState({isCutoutTool: isTool });
        document.getElementById("uploadproduct" + this.state.selectedIndex).click();
    }

    renderLoader() {
        return <div className='loader'>
            <CircularProgress color="secondary" />
        </div>
    }

    renderUploadDialog() {
        var { isDialogShow, loadingDialog } = this.state;

        return <CustomDialog className={styles.cotoutDialog}
            maxWidth={"600px"}
            open={isDialogShow}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleUploadClose}
            aria-describedby="alert-dialog-slide-description">
            {loadingDialog && this.renderLoader()}
            <CustomDialogTitle>{"Upload your products"}</CustomDialogTitle>
            <DialogContent>
                <div className={styles.cotoutDialogContainer}>
                    <div>Please note we can only display products with a transparent background.</div>
                    <div className={styles.cotoutDialogDescription} >Use our AI cut out tool below:</div>
                    <div className={styles.cotoutDialogCotoutButton} onClick={() => { this.continueUpload(true) }}>Cutout Tool</div>
                    <div className={styles.cotoutDialogDescription}>If your products are already on a transparent background, feel free to continue.</div>
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: "0 0 35px 0", backgroundColor: "#1a102c", color: "white", display: "flex", justifyContent: "center" }}>
                <div className={styles.cotoutDialogContinueButton} onClick={() => { this.continueUpload(false) }}>Continue</div>
            </DialogActions>
        </CustomDialog>
    }

    renderProductsUploadDialog() {
        var { isProductsDialogShow, loadingDialog } = this.state;

        return <CustomDialog className={styles.cotoutDialog}
            maxWidth={"600px"}
            open={isProductsDialogShow}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleUploadClose}
            aria-describedby="alert-dialog-slide-description">
            {loadingDialog && this.renderLoader()}
            <CustomDialogTitle>{"Upload your products"}</CustomDialogTitle>
            <DialogContent>
                <div className={styles.cotoutDialogContainer}>
                    <div>Please note we can only display products with a transparent background.</div>
                    <div className={styles.cotoutDialogDescription} >Use our AI cut out tool below:</div>
                    <div className={styles.cotoutDialogCotoutButton} onClick={() => { this.uploadProductsToServer(true) }}>Cutout Tool</div>
                    <div className={styles.cotoutDialogDescription}>If your products are already on a transparent background, feel free to continue.</div>
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: "0 0 35px 0", backgroundColor: "#1a102c", color: "white", display: "flex", justifyContent: "center" }}>
                <div className={styles.cotoutDialogContinueButton} onClick={() => { this.uploadProductsToServer(false) }}>Continue</div>
            </DialogActions>
        </CustomDialog>
    }

    uploadProductDialog(i) {        
        this.setState({isDialogShow: true, selectedIndex: i});
    }

    renderProductItem(item, i) {
        var { searchValue } = this.state;

        return (
            <section key={i} className={!searchValue.length || searchValue.length > 0 && item.name.toLowerCase().includes(searchValue.toLowerCase()) ? styles.productSection : styles.productSectionHided}>
                <div className={styles.productItemContainer}>
                    <div onDragStart={() => this.onStart(item)} className={styles.product} onClick={() => { this.uploadProductDialog(i) }}>
                        {item.thumbnailUrl
                            ? (<img className={styles.productImage}
                                src={item.thumbnailUrl + "?t=" + new Date().getTime()}
                                alt={"productImage" + i}
                                loading="lazy" />)
                            : (<img className={styles.productIcon}
                                src="./images/icons/picture_upload_black.png"
                                alt={"productImage" + i}
                                loading="lazy" />)
                        }

                        <input hidden type="file" id={"uploadproduct" + i} accept="image/png, image/jpg, image/jpeg" name="productFile" onChange={(e) => this.uploadProduct(e, item, i)}></input>
                    </div>

                    <div className={styles.productUrlContainer}>
                        <div className={styles.productUrlInputContainer}>
                            <InputBase
                                placeholder="Product name"
                                sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                                className={styles.productUrlTextField}
                                type="text"
                                variant="filled"
                                fullWidth
                                size="small"
                                onFocus={(e) => this.focusInput(e)}
                                onBlur={(e) => this.blurInput(e, item, i)}
                                onChange={(e) => this.setProductName(e.target.value, item, i)}
                                value={item.name} />
                            <InputBase
                                placeholder="Product store URL"
                                sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                                className={styles.productUrlTextField}
                                type="text"
                                variant="filled"
                                fullWidth
                                size="small"
                                onFocus={(e) => this.focusInput(e)}
                                onBlur={(e) => this.blurInput(e, item, i)}
                                onChange={(e) => this.setProductUrl(e.target.value, item, i)}
                                value={item.productUrl} />
                        </div>
                        {item.id > 0 &&
                            <div className={styles.iconProductContainer} onClick={() => this.deleteProduct(item, i)}>
                                <CloseIcon sx={{ color: "white", width: "15px", height: "15px" }} />
                            </div>
                        }                        
                    </div>
                </div>
            </section>);
    }

    renderNewProductItem(item, i) {
        return (
            <section key={'n'+i} className={styles.newProductSection}>
                <div className={styles.productItemContainer}>
                    <div className={styles.product}>
                        <img className={styles.productImage}
                            src={item.thumbnailUrl}
                            alt={"productImage" + i}
                            loading="lazy" />
                    </div>

                    <div className={styles.productUrlContainer}>
                        <div className={styles.productUrlInputContainer}>
                            <InputBase
                                placeholder="Product name"
                                sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                                className={styles.productUrlTextField}
                                type="text"
                                variant="filled"
                                fullWidth
                                size="small"
                                onFocus={(e) => this.focusInput(e)}
                                onBlur={(e) => this.blurInput(e, item, i, true)}
                                onChange={(e) => this.setNewProductName(e.target.value, item, i)}
                                value={item.name} />
                            <InputBase
                                placeholder="Product store URL"
                                sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                                className={styles.productUrlTextField}
                                type="text"
                                variant="filled"
                                fullWidth
                                size="small"
                                onFocus={(e) => this.focusInput(e)}
                                onBlur={(e) => this.blurInput(e, item, i, true)}
                                onChange={(e) => this.setNewProductUrl(e.target.value, item, i)}
                                value={item.productUrl} />
                        </div>

                        <div className={styles.iconProductContainer} onClick={() => this.deleteNewProduct(item, i)}>
                            <CloseIcon sx={{ color: "white", width: "15px", height: "15px" }} />
                        </div>
                    </div>
                </div>
            </section>);
    }

    renderFileDropZone = () => {
        return <div className={styles.fileDropZone}>            
            <div className={styles.fileUploadIconContainer}>
                <img className={styles.fileUploadIcon}
                    src="./images/icons/import_products.png"
                    alt="uploadIcon" />
            </div>
            <div className={styles.fileUploadTitle}>Select files to upload</div>
            <div>or drag and drop files here</div>
        </div>;
    }
   
    render() {
        var {
            searchValue,
            hide,
            products,
            isDialogShow,
            isAddProducts,
            isFilesLoaded,
            newProducts,
            isProductsDialogShow
        } = this.state;

        const fileTypes = ["JPG", "PNG", "JPEG"];
        const element = this.renderFileDropZone();
        var tutorialData = this.props.tutorialData;

        let contents = this.state.loading
            ? <p className={styles.productLoading}><em>Loading...</em></p>
            : isAddProducts
                ? !isFilesLoaded
                    ? <FileUploader
                        handleChange={(e) => this.handleChangeFile(e)}
                        name="file"
                        classes={styles.dropArea}
                        multiple
                        children={element}
                        hoverTitle="Drop Here"
                        types={fileTypes} />

                    : <>{newProducts.map((item, i) => this.renderNewProductItem(item, i))}</>
                : <BootstrapTooltip
                    key={"productsView"}                    
                    open={!hide && tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 2 && products && products.length < 4}
                    placement="right"
                    title={"Time to stock your store! Add your product’s images and a link to product page on your website as customers will use this to purchase the product."}>
                    <div>{products.map((item, i) => this.renderProductItem(item, i))}</div>
                </BootstrapTooltip>

        return (<>
            <div className={hide ? styles.myproductsHide : styles.myproducts}>
                <h4 className={styles.productTitle} >My Products</h4>
                <div className={styles.searchContainer}>
                    <SearchIcon sx={{ color: 'lightgray', mx: '5px' }} />
                    <InputBase
                        placeholder="Search"
                        sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.searchTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        size="small"
                        onFocus={(e) => this.focusInput(e)}
                        onBlur={(e) => this.blurInput(e)}
                        onChange={(e) => this.setValue(e.target.value)}
                        value={searchValue} />

                    {searchValue.length > 0
                        ? <IconButton onClick={() => this.setValue("")}>
                            <ClearOutlinedIcon sx={{ color: 'lightgray' }} />
                        </IconButton>
                        : ''}
                </div>
                <div className={!isAddProducts ? styles.productsScrollContainer : styles.productsScrollContainerBordered}>
                    {isAddProducts &&
                        <div className={styles.fileDropZoneCloseIconContainer} onClick={(e) => this.closeAddProducts(e)}>
                            <CloseIcon sx={{ color: "rgba(142, 148, 155, 1)", width: "17px", height: "17px" }} />
                        </div>
                    }
                    {contents}
                </div>
                {
                    !isAddProducts &&
                    <BootstrapTooltip
                        key={"myProductsViewAdd"}
                        open={!hide && tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 2}
                        className={styles.prodTooltip}
                        placement="right"
                        title={"You can upload multiple product images at once."}>
                        <div className={styles.addButton} onClick={() => { this.setValue(""); this.addProducts() }}>
                            <img className={styles.addProductsIcon} src="./images/icons/add_products.png" />
                            Add Products
                        </div>
                    </BootstrapTooltip>
                }
                {
                    isAddProducts &&
                    <div className={styles.addButton} onClick={() => { this.setValue(""); this.setState({isProductsDialogShow: true}); }}>
                        Upload Products
                    </div>
                }
            </div>

            {isDialogShow && this.renderUploadDialog()}
            {isProductsDialogShow && this.renderProductsUploadDialog()}
        </>)
    }

    async uploadProductsToServer(isTool) {
        
        var { newProducts, newFiles, products } = this.state;

        const form = new FormData();

        form.append('products', JSON.stringify(newProducts));
        form.append("isTool", isTool);

        for (let i = 0; i < newFiles.length; i++) {
            form.append('files', newFiles[i]);
        }

        const response = await fetch('api/Product/UploadProducts', {
            method: 'POST',
            body: form
        });

        const data = await response.json();

        newProducts.forEach(item => {
            URL.revokeObjectURL(item.thumbnailUrl);
        });

        if (response.status != 200) {
            console.error('HTTP response code != 200' + data.message);
            this.setState({loadingDialog: false, isDialogShow: false});
            return;
        }
        else if(data.length) {
            let products = this.state.products;
           

            if(products.find(el => el.id === 0)) {
                products.pop();
            }

            products.push(...data);
            products.push({ id: 0, thumbnailUrl: "", productUrl: "", name: "" });
        }

        this.setState({ 
            products: products, 
            loadingDialog: false, 
            isProductsDialogShow: false,
            newFiles: [],
            newProducts: [],
            isAddProducts: false, 
            isFilesLoaded: false
        });
    }

    async uploadProductToServer(file, item, i) {
        
        const form = new FormData();

        if (file) {
            this.setState({loadingDialog: true});
            form.append("file", file);
            form.append("isTool", this.state.isCutoutTool);
        }

        form.append("id", item.id);
        form.append("name", item.name);
        form.append("productUrl", item.productUrl);

        const response = await fetch('api/Product/UploadProduct', {
            method: 'POST',
            body: form
        });

        const data = await response.json();

        if (response.status != 200) {
            console.error('HTTP response code != 200' + data.message);
            this.setState({loadingDialog: false, isDialogShow: false});
            return;
        }
        else {
            let products = this.state.products;
            products[i] = data;

            if(!products.find(el => el.id === 0)) {
                products.push({ id: 0, thumbnailUrl: "", productUrl: "", name: "" });
            }

            this.setState({ products: products, loadingDialog: false, isDialogShow: false });
        }
    }
}