import { Navigate } from 'react-router-dom';
import { isAdmin, isStoreExist, isUserAuth, clearLocalStorage } from './HandleJWT';
 
const RouteGuard = ({Component}) => { 
    console.log(Component.displayName);

    if(isUserAuth()) {
        if(isAdmin() && Component.displayName === "BugReportView") {
            return <Component/>
        }
        else if((isStoreExist() && !isAdmin()) || Component.displayName === "SetupStore") {
            return <Component/>
        }
        else if(!isStoreExist() && !isAdmin()) {
            return <Navigate to='/setup-store' />
        }
        else {
            clearLocalStorage();
            return <Navigate to='/login' />
        }
    }
    else{
        clearLocalStorage();
        return <Navigate to='/login' />
    }
    
   return isUserAuth() 
    ? isStoreExist() || Component.displayName === "SetupStore" ? <Component/> : <Navigate to='/setup-store' /> 
    : <Navigate to='/login' />          
};
 
export default RouteGuard;