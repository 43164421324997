
import { Component } from "react";
import { CustomDialog, CustomDialogTitle } from "./CustomDialog"
import * as React from 'react';
import styles from "./../CSSModules/Popups/DeleteStore.module.css";
import { DialogContent, DialogActions, Slide, CircularProgress } from "@mui/material";
import { logout } from "../Auth/HandleJWT";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class DeleteStore extends Component {

    constructor() {
        super();
        this.state = {
            name: "DeleteStore",
            open: true,
            loading: false,
        };
    }


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.onPopupClose();
    };

    async deleteStore() {
        this.setState({loading: true});
        const response = await fetch(`api/Account/DeleteUserData`, { method: 'DELETE' });
        const data = await response.json();

        if (response.status != 200) {
          console.error(data);
        }
        else {
            logout();
        }
    }

    renderLoader() {
        return <div className='loader'>
            <CircularProgress color="secondary" />
        </div>
    }

    render() {

        var { open, loading } = this.state;      

        return (
            <CustomDialog className={styles.promoteStoreDialog}
                maxWidth={"600px"}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-describedby="alert-dialog-slide-description">
                {loading && this.renderLoader()}
                <CustomDialogTitle>{"We're sorry to see you go!"}</CustomDialogTitle>
                <DialogContent>
                    <div className={styles.promoteLabel}>
                    Are you sure you want to delete your 3D store? All contents will be permanently removed, your
                    plan will be cancelled and you will no longer have access to your virtual store. Your subscription will
                    end at the end of the current billed month.
                    </div>
                </DialogContent>
                <DialogActions sx={{ padding: "25px 0 35px 0", backgroundColor: "#1a102c", color: "white", display: "flex", justifyContent: "center" }}>
                    <div className={styles.cancelDeleteButton} onClick={() => {this.handleClose()}}>Cancel</div>
                    <div className={styles.yesDeleteButton} onClick={() => {this.deleteStore()}}>Yes, Delete</div>
                </DialogActions>
            </CustomDialog>            
        )
    }
}