import React, { Component } from 'react';
import { AuthInterceptor } from './components/Auth/AuthInterceptor';
import { Layout } from './components/Layout';
import './custom.scss';
import AppRoutes from './router/AppRouter';

export default class App extends Component {
  static displayName = App.name;

  render() {
    AuthInterceptor();

    return (
      <Layout>
        <AppRoutes/>       
      </Layout>
    );
  }
}
