// In Sidenav.js
import styles from "./CSSModules/Sidenav.module.css";
import { toolData } from "../lib/toolData";
import { actionData } from '../lib/actionData';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Component } from "react";
import { AssetsView } from "./Sidebar/AssetsView";
import { MyStoreView } from "./Sidebar/MyStoreView";
import { MyProductsView } from "./Sidebar/MyProductsView";
import { CustomiseView } from "./Sidebar/CustomiseView";
import { WallArtView } from "./Sidebar/WallArtView";
import { SettingsView } from "./Sidebar/SettingsView";
import { PublishView } from "./Sidebar/PublishView";
import { webModelToUnityModel } from "../models/unityUser.model";


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#6219D3",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#6219D3",
    marginLeft: "30px !important",
    fontSize: 16,
  },
}));

const TutorialTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#6219D3",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#6219D3",        
    fontSize: 16,
    borderRadius: 16,
    padding: '10px',
    marginLeft: "30px !important",
    textAlign: 'center',
  },
}));

export class Sidenav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "ToolBar",
      open: false,
      selectedId: 0,
      isUnityAssetSelected: false,      
      unityUser: null,
      tutorialData: null,
      isAssetsTutorialTooltipOpen: false
    };
    
    this.onObjectSelect = this.onObjectSelect.bind(this);
    this.onUnselect = this.onUnselect.bind(this);
  }

  componentDidMount() {
    window.onObjectSelect = this.onObjectSelect;
    window.onUnselect = this.onUnselect;

    this.getUserStoreData();
  }

  async getUserStoreData() {
    const response = await fetch('api/account/getuserstoredata');
    const data = await response.json();

    if (response.status != 200) {
      console.error('HTTP response code = '+response.status +' message='+ data?.message);
      return;
    }
    else {
      console.log(data);
      this.setState({ unityUser: data.user});

      if(data.isStorePublished) {
        this.props.storePublished(data.storeId);
      }

      setTimeout(()=>this.updateUserDataOnUinity(), 250);
     // setTimeout(()=>this.updateUserDataOnUinity(), 750);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('onUnityMount', this.onUnityLoaded);
    window.removeEventListener('onObjectSelect', this.onObjectSelect);
    window.removeEventListener('onUnselect', this.onUnselect);
  }

  updateUserDataOnUinity() {
    if(window.unityMounted === true && this.state.unityUser) {
      console.log("Web -> Update User data");
      var jsonStr = JSON.stringify(webModelToUnityModel(this.state.unityUser, true));  
      console.log("Web->"+jsonStr);  
      window.unity.SendMessage('bloodbridge', "updateuserdata", jsonStr);
    }
  }

  onUnityUserDataChange(userData) {
    console.log("Parent -> ");
    console.log(userData);

    this.setState({unityUser: userData});

    this.updateUserDataOnUinity();
    this.saveStoreData();
  }

  async saveStoreData(){
    const response = await fetch(`api/Account/UpdateStoreData/${this.state.unityUser.id}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(this.state.unityUser)
    });

    const data = await response.json();

    if (response.status != 200) {
      console.error('HTTP response code status->' + response.status + ' message->' + data);     
      return;
    }
  }

  onObjectSelect(){    
    this.setState({isUnityAssetSelected: true, selectedId: this.state.selectedId === 9 ? 0 : this.state.selectedId});
  }

  onUnselect(){    
    this.setState({
      isUnityAssetSelected: false, 
      isDeleteOpen: false, 
      selectedId: this.state.selectedId === 9 ? 0 : this.state.selectedId
    });    
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open, selectedId: !this.state.open ? 0 : this.state.selectedId});
  }

  closeMenu = () => {
    this.setState({ selectedId: 0 });
  }

  setSelected(id) {
    this.setState({ selectedId: this.state.selectedId === id ? 0 : id, open: false, isAssetsTutorialTooltipOpen: false });
  }

  deleteSelectedAsset() {
    if(window.unityMounted) {
      window.unity.SendMessage('bloodbridge', "deleteselectedobject", "");
    }
  }

  cancelDelete() {
    this.setState({ selectedId: 0, open: false });
  }

  undoAssets() {
    if (window.unityMounted) {
      window.unity.SendMessage('bloodbridge', "undo", "");
    }
  }

  redoAssets() {
    if (window.unityMounted) {
      window.unity.SendMessage('bloodbridge', "redo", "");
    }
  }

  renderActionToolData(item) {
    return (
      <div className={this.state.selectedId === item.id ? styles.sideitemSelected : styles.sideitem}>
        <img className={styles.sideimg} src={item.icon} />
        <span className={this.state.open ? styles.linkText : styles.linkTextClosed}>{item.text}</span>
      </div>  
    );
  }

  renderActionTool(item) {
    switch(item.id){
      case 19:
        return (
          <BootstrapTooltip key={item.id}
              title={this.state.open || this.state.selectedId === item.id ? '' : item.text} 
              placement="right" 
              onClick={()=>{this.state.isUnityAssetSelected && this.setSelected(item.id)}}>
            {this.renderActionToolData(item)}
          </BootstrapTooltip>
        )
      case 7:
        return (
          <BootstrapTooltip key={item.id} title={this.state.open ? '' : item.text} placement="right" onClick={()=> this.undoAssets()}>
            {this.renderActionToolData(item)}
          </BootstrapTooltip>
        )
      case 8:
        return (
          <BootstrapTooltip key={item.id} title={this.state.open ? '' : item.text} placement="right" onClick={()=> this.redoAssets()}>
            {this.renderActionToolData(item)}
          </BootstrapTooltip>
        )
      case 10:
        return (<BootstrapTooltip key={item.id}
                  title={this.state.open || this.state.selectedId === item.id ? '' : item.text} 
                  placement="right" 
                  onClick={()=>this.setSelected(item.id)}>
                  {this.renderActionToolData(item)}            
                </BootstrapTooltip>)
      default:
        return "";
    }
  }

  renderDeletePopup() {
    return (<div className={styles.delete}>
      <div className={styles.deleteContent}>
        <h4>Delete</h4>
        <div className={styles.deleteDescription}>Are you sure you would like to delete the selected item?</div>
      </div>
      <div className={styles.deleteActions}>
        <div className={styles.deleteActionButton} onClick={()=> this.deleteSelectedAsset()}>Yes</div>
        <div className={styles.deleteActionButton} onClick={()=> this.cancelDelete()}>Cancel</div>
      </div>
    </div>);    
  }

  onTutorialStepChange(tutorialData) {

    this.setState({ tutorialData: tutorialData });
    
    if (tutorialData.isTutorialOpen) {
      switch (tutorialData.stepId) {
        case 1:          
          this.setSelected(3);
          break;
        case 2:          
          this.setSelected(2);
          break;
        case 3:
          this.setState({isAssetsTutorialTooltipOpen: true, selectedId: 0});
          //this.setSelected(1);
          break;
        case 4:
          this.setState({isAssetsTutorialTooltipOpen: true, selectedId: 0});
          //this.setSelected(6);
          break;
        default:
          break;
      }
    }
  }

  getTutorialHighLightClass(toolId) {
    var { tutorialData } = this.state;
    
    if(!tutorialData || !tutorialData.isTutorialOpen) {
      return "";
    }

    var tmpId = tutorialData.stepId*10 + toolId;

    switch(tmpId) {
      case 13:
      case 14:
      case 22:
      case 25:
      case 31:
      case 32:
      case 35: 
      case 46: 
        return styles.tutorialSelected;
      default:
        return "";
    }
  }

  render() {
    var { 
      open, 
      selectedId, 
      isUnityAssetSelected, 
      tutorialData, 
      isAssetsTutorialTooltipOpen 
    } = this.state;

    return (
      <div className={(open ? styles.sidenav : styles.sidenavClosed)
        + ' ' + (selectedId > 0 && selectedId < 6 ? styles.sidenavActiveItem : selectedId === 6 || selectedId >= 9 ? styles.sidenavBottomActiveItem : '')}>
        <button className={styles.menuBtn} onClick={() => this.toggleOpen()}>
          {open
            ? <img className={styles.sideimg} src="./images/icons/sidebar_collaps.png" />
            : <img className={styles.sideimg} src="./images/icons/sidebar_expand.png" />}
        </button>
        <div className={styles.toolContainer}>
          {toolData.map(item => {
            if (tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 3 && item.id === 1) {
              return <TutorialTooltip
                key={"ts"+item.id}
                open={isAssetsTutorialTooltipOpen}
                placement="right"
                title={"It's finally the last step! Let’s add some rails, shelves or other available furniture to your store."}>
                <div className={(selectedId === item.id ? styles.sideitemSelected : styles.sideitem) + " " + (tutorialData ? this.getTutorialHighLightClass(item.id) : "")} onClick={() => this.setSelected(item.id)}>
                  <img className={styles.sideimg} src={item.icon} />
                  <span className={open ? styles.linkText : styles.linkTextClosed}>{item.text}</span>
                </div>
              </TutorialTooltip>
            }
            else if(tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 4 && item.id === 6) {
              return <TutorialTooltip
                key={"ts"+item.id}
                open={isAssetsTutorialTooltipOpen}
                placement="right"
                title={"Congratulations on finishing your store! You can come back here anytime to make changes or updates, but for now let’s open your store for customers!"}>
                <div className={(selectedId === item.id ? styles.sideitemSelected : styles.sideitem) + " " + (tutorialData ? this.getTutorialHighLightClass(item.id) : "")} onClick={() => this.setSelected(item.id)}>
                  <img className={styles.sideimg} src={item.icon} />
                  <span className={open ? styles.linkText : styles.linkTextClosed}>{item.text}</span>
                </div>
              </TutorialTooltip>              
            }
            else {
              return <BootstrapTooltip key={item.id} title={open || selectedId === item.id ? '' : item.text} placement="right">
                <div className={(selectedId === item.id ? styles.sideitemSelected : styles.sideitem) + " " + (tutorialData ? this.getTutorialHighLightClass(item.id) : "")} onClick={() => this.setSelected(item.id)}>
                  <img className={styles.sideimg} src={item.icon} />
                  <span className={open ? styles.linkText : styles.linkTextClosed}>{item.text}</span>
                </div>
              </BootstrapTooltip>
            }
          })}
        </div>
        <div className={styles.actionContainer}>
          {actionData.map(item => { return this.renderActionTool(item) })}
        </div>

        {this.state.unityUser ? (<>
          {selectedId === 1 && <AssetsView tutorialData={this.state.tutorialData} parent={this} root={this.props.parent} onDropTutorialFinished={() => this.props.onDropTutorialFinished()}></AssetsView>}
          {selectedId === 2 && <MyProductsView tutorialData={this.state.tutorialData} parent={this} root={this.props.parent}></MyProductsView>}
          {selectedId === 3 && <MyStoreView tutorialData={this.state.tutorialData} unityUser={this.state.unityUser} onUnityUserDataChange={(userData) => this.onUnityUserDataChange(userData)} parent={this} root={this.props.parent}></MyStoreView>}
          {selectedId === 4 && <CustomiseView tutorialData={this.state.tutorialData} unityUser={this.state.unityUser} onUnityUserDataChange={(userData) => this.onUnityUserDataChange(userData)} parent={this} root={this.props.parent}></CustomiseView>}
          {selectedId === 5 && <WallArtView tutorialData={this.state.tutorialData} parent={this} root={this.props.parent}></WallArtView>}
          {selectedId === 6 && <PublishView storePublished={(storeId) => this.props.storePublished(storeId)} tutorialData={this.state.tutorialData}></PublishView>}

          {selectedId === 9 && isUnityAssetSelected && this.renderDeletePopup()}
          {selectedId === 10 && <SettingsView></SettingsView>}
        </>) : ""}
      </div>
    )
  }
}