
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import RouteGuard from "../components/Auth/RouteGuard";
 
//history
import history from "../helpers/history";
 
//pages
import { AdminHome } from "../components/AdminHome";
import { LoginView } from "../components/Auth/LoginView";
import { RegisterView } from "../components/Auth/RegisterView";
import { SetupStore } from "../components/SetupStore";
import StoreHome from "../components/StoreHome";
import { BugReportView } from "../components/Admin/BugReportView";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
 
function AppRoutes() {
   return (
       <BrowserRouter basename={baseUrl} history={history}>
           <Routes>
               <Route exact path="/" element={<RouteGuard Component={AdminHome}/>} />
               <Route exact path="/admin" element={<RouteGuard Component={BugReportView}/>} />
               <Route exact path="/login" element={<LoginView/>} />
               <Route exact path="/register" element={<RegisterView/>}/>
               <Route exact path="/setup-store" element={<RouteGuard Component={SetupStore}/>} />
               <Route exact path="/store/:id/:storename" element={<StoreHome/>} />
               <Route exact path="/store/:id/:storename/:room" element={<StoreHome/>} />
               <Route exact path="*" element={<Navigate to='/' replace />} />
           </Routes>
        </BrowserRouter>
   );
}
 
export default AppRoutes