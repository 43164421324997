
import { Component } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import styles from "./../CSSModules/Popups/AvatarsDialog.module.scss";
import { Checkbox, DialogContent, Slide } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AvaDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        backgroundColor: "#F4F6FC",
        color: "black",
        maxHeight: "90%",
        borderRadius: "16px",
    }
}));

export function AvaDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ padding: "25px 0px", fontFamily: 'Cosmata Bold', textAlign: "center", backgroundColor: "#F4F6FC", color: "black" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


export class AvatarsDialog extends Component {

    constructor() {
        super();
        this.state = {
            name: "AvatarsDialog",
            open: true,
            avatarId: -1,
            avatars: [0, 1, 2, 3, 4, 5]
        };
    }

    componentWillUnmount() { }

    handleClose = () => {
        this.setState({ open: false });
    };

    setAvatar(id) {
        this.setState({ avatarId: id });
    }


    renderAvatars() {
        var { open, avatars, avatarId } = this.state;

        return <AvaDialog className="avatarsDialog"
            sx={{ '& .MuiPaper-root': { bgcolor: "#F4F6FC", borderRadius: "16px", width: "770px", maxWidth: "770px", padding: "0", } }}
            maxWidth={"770px"}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description">
            <AvaDialogTitle onClose={this.handleClose}></AvaDialogTitle>
            <DialogContent>
                <h5 className={styles.avatarsTitle}>Choose your avatar</h5>

                <div className={styles.avatarsContainer}>
                    {avatars.map(item =>
                        <div key={"a" + item} className={styles.avatarContainer} onClick={() => this.setAvatar(item)}>
                            <img className={styles.avatarImg} src={"./images/avatars/" + item + ".png"} />
                            <Checkbox checked={avatarId === item}
                                icon={<RadioButtonUncheckedOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                                className={styles.avatarCheckbox}
                                size="small"
                                sx={{
                                    color: "#E6D9FA",
                                    '&.Mui-checked': {
                                        color: "#6219D3",
                                    },
                                }} />
                        </div>
                    )}
                </div>

                <div className={styles.actionAvatarsButton}>
                    <div className={styles.avatarsApplyButton} onClick={() => this.props.onAvatarChange(avatarId)}>Apply</div>
                </div>

            </DialogContent>
        </AvaDialog>;
    }

    render() {

        return (<>
            {this.renderAvatars()}
        </>
        )
    }
}