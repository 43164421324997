import { CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import styles from "./../CSSModules/Admin/BugReport.module.css";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { logout } from '../Auth/HandleJWT';

export class BugReportView extends Component {
  static displayName = "BugReportView";

  constructor(props) {
    super(props);
    this.state = {
      name: "BugReport",
      loading: true,
      columns: [
        { field: 'date', type: 'date', headerName: 'Date', width: 130, valueGetter: ({ value }) => value && new Date(value) },
        { field: 'userName', headerName: 'UserName', width: 200, minWidth: 100, },
        { field: 'email', headerName: 'Email', width: 250, minWidth: 150, },
        { field: 'rating', headerName: 'Rating', width: 100, minWidth: 100, },
        {
          field: 'message',
          headerName: 'Message',
          description: 'This column is not sortable.',
          sortable: false,
          minWidth: 150,
          flex: 1
        },
      ],
      data: []
    };
  }

  componentDidMount() {
    this.getBugsData();
  }

  componentWillUnmount() {

  }

  async getBugsData() {
    const response = await fetch(`api/Admin/GetBugsData`);
    const data = await response.json();

    if (response.status != 200) {
      logout();
    }
    else {
      this.setState({ data: data, loading: false });
    }
  }

  renderTable() {
    var { columns, data } = this.state;

    return <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={data}
        disableRowSelectionOnClick
        disableDensitySelector
        disableColumnMenu
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
        sx={{
          boxShadow: 2,
          border: 2,
          color: 'black',
          paddingLeft: '15px',
          fontFamily: 'Cosmata',
          borderColor: 'black',
          '& .MuiDataGrid-cell:hover': {
            color: 'gray',
          },
          '.MuiDataGrid-columnSeparator': {
            visibility: 'visible',
          },
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
          },
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '.MuiTablePagination-root p': {
            marginBottom: '0px!important;'
          }
        }}
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </div>
  }

  render() {
    let contents = this.state.loading
      ? <div className='loader'>
        <CircularProgress color="secondary" />
      </div>
      : this.renderTable();
    return <div className={styles.tableContainer}>
      <div className={styles.logoutAdminButton} onClick={()=>{logout()}}>Logout</div>
      {contents}
    </div>;
  }
}
