import { CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import { withRouter } from '../router/withRouter';
import { webModelToUnityModel } from "../models/unityUser.model";
import { AssistensChatBot } from './ChatBot/AssistensChatBot';
import { isMobile } from 'react-device-detect';
import { Feedback } from './Popups/FeedbackDialog';
import { VideoChat } from './Popups/VideoChatDialog';
import AgoraRTC from 'agora-rtc-sdk-ng';
import styles from "./CSSModules/StoreHome.module.scss";
import { CameraSwitch } from './Popups/CameraSwitch';


export class StoreHome extends Component {
  static displayName = StoreHome.name;

  constructor(props) {
    super(props);
    this.state = {
      name: "StoreHome",
      unityMounted: false,
      loading: true,
      sceneState: null,
      storeData: null,
      id: 0,
      storename: "",
      roomName: "",
      remoteUsers: [],
      appId: "c3e98d975baf4a28bf8be2dbe2b9bf84",
      certificate: "",
      uid: "",
      token: "",
      client: null,
      localAudioTrack: null,
      localVideoTrack: null,
      screenTrack: null,
      isVideoPlay: false,
      isAudioPlay: false,
      isSharingEnabled: false,
      avatarId: -1,
      avatarKey: "avatar"
    };

    this.onUnityMounted = this.onUnityMounted.bind(this);
    this.onStoreInitialized = this.onStoreInitialized.bind(this);
  }


  componentDidMount() {
    const { id, storename, room } = this.props.params;
    this.setState({ id: id, storename: storename, roomName: room });

    console.log(this.props.params);

    if (id && storename) {
      this.getSceneData(id, storename, room ? room : null);
    }

    if(localStorage.getItem(this.state.avatarKey)) {
      this.setState({avatarId: +localStorage.getItem(this.state.avatarKey)});
    }

   

    window.onUnityMount = this.onUnityMounted;
    window.onStoreInitialized = this.onStoreInitialized;
  }

  async getSceneData(id, storename, room) {

    const response = await fetch(`api/Auth/GetStoreData/${id}/${storename}/${room}`);

    const data = await response.json();

    if (response.status != 200) {
      window.location.href = '/';
    }
    else {
      this.setState({ sceneState: data.sceneState, storeData: data.unityUser, uid: data.uid, token: data.token });
      if (window.unityMounted) {
        this.setSceneState();
        this.updateUserDataOnUinity();
      }
      console.log(data);
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('onUnityMount', this.onUnityMounted);
    var { client } = this.state;
    if (client) {
      await client.leave();
      client.removeAllListeners();
    }
  }

  onStoreInitialized() {
    console.log("Unity3D initialized");

    setTimeout(() => this.setState({ loading: false }), 1500);

    if (this.state.roomName) {
      setTimeout(() => this.initMeeting(), 1000);
      setTimeout(() => this.initOnUnityMeeting(), 1500);
    }

    if (isMobile) {
      setTimeout(() => window.unity.SendMessage('bloodbridge', "enablejoystick", ""), 500);
    }
  }

  onUnityMounted() {
    console.log("Unity3D loaded");
    window.unityMounted = true;

    if (this.state.storeData) {
      this.setSceneState();
      this.updateUserDataOnUinity();
    }
  }

  updateUserDataOnUinity() {
    if (this.state.storeData) {
      var jsonStr = JSON.stringify(webModelToUnityModel(this.state.storeData, false));
      console.log("Web->" + jsonStr);
      window.unity.SendMessage('bloodbridge', "updateuserdata", jsonStr);
    }
  }

  setSceneState() {
    if (this.state.sceneState) {
      console.log("set scene ->" + JSON.stringify(this.state.sceneState));
      console.log(this.state.sceneState);
      setTimeout(() => {
        window.unity.SendMessage('bloodbridge', "initscenestate", this.state.sceneState);
      }, 500);
    }
  }

  initOnUnityMeeting() {
    var { avatarId, roomName } = this.state;
    console.log(avatarId);
    if(avatarId !== -1) {
      window.unity.SendMessage('bloodbridge', "changeavatar", avatarId);
      window.unity.SendMessage('bloodbridge', "connecttoroom", roomName);
    }
  }

  async initMeeting() {

    const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    //client.init(this.state.appId);

    //Join a channel by passing a channel name and a user ID:


    //Publish the local tracks to the channel:


    //await client.unpublish(localVideoTrack); // to stop transmitting video
    //await client.unpublish(localAudioTrack); // to stop transmitting audio

    //localVideoTrack.play(`local-${this.state.uid}`);
    // Subscribe to the remote video track enabled event



    client.on("user-unpublished", (user, mediaType) => {
      console.log(user);
      console.log(mediaType);
      console.log(user.uid + " has unpublish");
    });

    client.on("videoTrackEnabled", (evt) => {
      console.log(`Remote video track ${evt.track.trackId} enabled`);
    });

    // Subscribe to the remote video track disabled event
    client.on("videoTrackDisabled", (evt) => {
      console.log(`Remote video track ${evt.track.trackId} disabled`);
    });

    client.on("user-unpublished", (user) => {
      console.log(user);

      console.log(user.uid + " has unpublish");
    });

    client.on("user-left", user => {
      console.log(user.uid + " has left the channel");
      var { remoteUsers } = this.state;
      var index = remoteUsers.findIndex(item => item.uid === user.uid);
      if (index > -1) {
        remoteUsers.splice(index, 1);
        this.setState({ remoteUsers: remoteUsers });
      }
    });

    client.on("peer-leave", user => {
      console.log("Remote user left the channel: " + uid);
      var { remoteUsers } = this.state;
      var index = remoteUsers.findIndex(item => item.uid === user.uid);
      if (index > -1) {
        remoteUsers.splice(index, 1);
        this.setState({ remoteUsers: remoteUsers });
      }
    });

    client.on("stream-removed", evt => {
      // Handle the stream being removed
      console.log("Remote stream removed: ", evt);
    });

    client.on("stream-added", evt => {
      // Handle the stream being added
      console.log("Remote stream added: ", evt);

      // Subscribe to the stream
      client.subscribe(evt.stream, err => {
        console.log("Failed to subscribe to remote stream: ", err);
      });
    });

    client.on('user-published', async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      console.log(mediaType);

      var { remoteUsers } = this.state;
      var index = remoteUsers.findIndex(item => item.uid === user.uid);

      if (index === -1) {
        this.setState({ remoteUsers: [...remoteUsers, user] });
      }
      else {
        remoteUsers[index] = user;
        this.setState({ remoteUsers: remoteUsers });
      }

      if (mediaType === 'video') {
        const remoteVideoTrack = user.videoTrack;
        if (remoteVideoTrack) {
          remoteVideoTrack.play(`remote-${user.uid}`);
        }
      }

      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        if (remoteAudioTrack) {
          remoteAudioTrack.play();
        }
      }
    });

    await client.join(this.state.appId, this.state.roomName, this.state.token, this.state.uid);

    //Create a local audio and video track:
    const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    const localVideoTrack = await AgoraRTC.createCameraVideoTrack();

    await client.publish([localAudioTrack, localVideoTrack]);

    localVideoTrack.setEnabled(false);
    localAudioTrack.setEnabled(false);

    this.setState({ client: client, localAudioTrack: localAudioTrack, localVideoTrack: localVideoTrack });
  }

  async playVideo(isPlay) {
    var { client, localVideoTrack } = this.state;
    if (!client) {
      return;
    }

    if (isPlay) {
      await localVideoTrack.setEnabled(true);
      await client.publish(localVideoTrack);
      localVideoTrack.play(`local-${this.state.uid}`);
    }
    else {
      await localVideoTrack.setEnabled(false);
      // await client.unpublish(localVideoTrack);      
    }

    this.setState({ isVideoPlay: isPlay });
  }

  async playAudio(isPlay) {
    var { client, localAudioTrack } = this.state;
    if (!client) {
      return;
    }

    if (isPlay) {
      await localAudioTrack.setEnabled(true);
    }
    else {
      await localAudioTrack.setEnabled(false);
    }

    this.setState({ isAudioPlay: isPlay });
  }

  async shareScreen(isShare) {
    var { client, localVideoTrack, isSharingEnabled } = this.state;

    console.log(isShare && !isSharingEnabled);
    console.log(!isSharingEnabled);

    if (isShare && !isSharingEnabled) {

      if (localVideoTrack) {
        console.log("if video enabled");
        await localVideoTrack.setEnabled(false);
        await client.unpublish(localVideoTrack);
        this.setState({ isVideoPlay: false });
      }

      // Create a screen track for screen sharing.
      var newScreenTrack = await AgoraRTC.createScreenVideoTrack();
      //await localVideoTrack.replaceTrack(newScreenTrack, true);     

      await newScreenTrack.setEnabled(true);
      await client.publish(newScreenTrack);
      newScreenTrack.play(`local-${this.state.uid}`);

      newScreenTrack.on('track-ended', async () => {
        console.log('track-ended');

        await newScreenTrack.setEnabled(false);
        await client.unpublish(newScreenTrack);
        this.setState({ screenTrack: null, isSharingEnabled: false });

      });

      this.setState({ screenTrack: newScreenTrack, isSharingEnabled: isShare, localVideoTrack: localVideoTrack });

    } else {
      // Replace the screen track with the local video track.
      //await screenTrack.replaceTrack(localVideoTrack, true);
      // await screenTrack.stopScreenSharing();
      // await screenTrack.setEnabled(false);
      // await client.unpublish(screenTrack);
      //if(localVideoTrack) {
      //  await client.publish(localVideoTrack);
      //}
      // Update the button text.
      //this.setState({ screenTrack: null, isSharingEnabled: isShare });
    }
  }

  onAvatarChange(avatarId) {
    this.setState({ avatarId: avatarId }, (callback) => this.initOnUnityMeeting());
  }

  renderVideoContainers() {
    var { remoteUsers, isVideoPlay, isAudioPlay, isSharingEnabled } = this.state;

    return <div className={styles.videoContainers}>
      <div className={styles.localVideoContainer + " " + styles.item}>
        <div id={`local-${this.state.uid}`} className={isVideoPlay || isSharingEnabled ? styles.videoContainer : styles.videoContainerHided}>
          <img className={styles.videoAvatar} src="./images/icons/video/user_avatarl.png" />
        </div>
        <div className={!isVideoPlay && !isSharingEnabled ? styles.videoContainer : styles.videoContainerHided}>
          <img className={styles.videoAvatar} src="./images/icons/video/user_avatarl.png" />
        </div>
        <div className={styles.videoActions}>

          {isSharingEnabled
            ? <div className={styles.emptyImgContainer}></div>
            : <img src="./images/icons/video/screen-share.png" onClick={() => this.shareScreen(true)} />
          }
          {isAudioPlay
            ? <img src="./images/icons/video/microphone.png" onClick={() => this.playAudio(false)} />
            : <img src="./images/icons/video/microphone-off.png" onClick={() => this.playAudio(true)} />
          }
          {isVideoPlay
            ? <img src="./images/icons/video/video.png" onClick={() => this.playVideo(false)} />
            : <img src="./images/icons/video/video-off.png" onClick={() => this.playVideo(true)} />
          }
        </div>
      </div>

      {remoteUsers.map(user => {
        return <div key={user.uid} className={styles.remoteVideoContainer + " " + styles.item}>
          <div id={`remote-${user.uid}`} className={user.videoTrack ? styles.videoContainer : styles.videoContainerHided}>
            <img className={styles.videoAvatar} src="./images/icons/video/user_avatarl.png" />
          </div>
          <div className={!user.videoTrack ? styles.videoContainer : styles.videoContainerHided}>
            <img className={styles.videoAvatar} src="./images/icons/video/user_avatarl.png" />
          </div>
        </div>
      })
      }

    </div>;
  }

  render() {
    var { loading, roomName, avatarId, roomName } = this.state;

    let contents = loading
      ? <div className='loader'>
        <CircularProgress color="secondary" />
      </div>
      : roomName && this.renderVideoContainers();

    return <>
      {contents}
      <AssistensChatBot key="assistensChatbot" />
      <Feedback key="feedback" />
      {!loading && !roomName && <VideoChat urlParams={this.props.params} key="videochat" />}
      {!loading && <CameraSwitch openAvatarSelection={roomName && avatarId === -1} onAvatarChange={(avatarId) => this.onAvatarChange(avatarId)} roomName={roomName} key="cameramode" />}
    </>;
  }
}

export default withRouter(StoreHome)
