export const toolData = [
    {
        id: 1,
        icon: "./images/icons/add_fixtures.png",
        text: "Add Fixtures"
    },
    {
        id: 2,
        icon: "./images/icons/my_products.png",
        text: "My Products"
    },
    {
        id: 3,
        icon: "./images/icons/my_store.png",
        text: "My Store"
    },
    {
        id: 4,
        icon: "./images/icons/store_theme.png",
        text: "Store Theme"
    }
    ,
    {
        id: 5,
        icon: "./images/icons/wall_art.png",
        text: "Wall Art"
    },
    {
        id: 6,
        icon: "./images/icons/publish.png",
        text: "Publish"
    }
]