import { Alert, Checkbox, CircularProgress, InputBase } from '@mui/material';
import React, { Component } from 'react';
import { LoginUserModel } from '../../models/loginUser.model';
import styles from "./../CSSModules/Login.module.css";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { clearLocalStorage, isAdmin, saveToken } from './HandleJWT';

export class LoginView extends Component {
  static displayName = LoginView.name;

  constructor() {
    super();

    this.state = {
      name: "Login",
      loading: false,
      user: new LoginUserModel("", "", false),      
      showPassword: false,
      showCPassword: false,
      openSnackBar: false,
      snackBarType: "error",
      snackBarMsg: ""
    };

    clearLocalStorage();
  } 

  handleClickShowPassword = () => this.setState({showPassword: !this.state.showPassword});
  handleMouseDownPassword = (event) => event.preventDefault();

  handleChange = (value, arg) => this.updateUserField(arg, value);
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({openSnackBar: false});
  };

  setSnackBarMessage(isOpen, isError, message) {
    if(isError) 
      this.setState({snackBarType: "error", snackBarMsg: message, openSnackBar: isOpen });
    else 
      this.setState({snackBarType: "success", snackBarMsg: message, openSnackBar: isOpen});
  }

  updateUserField(field, value) {
    let _user = this.state.user;

    if (this.state.user != null)
      switch (field) {        
        case "email": _user.email = value; break;
        case "password": _user.password = value; break;    
      }

    this.setState({ user: _user });
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  isFormValid = () => {
    return !this.validateField("password") && !this.validateField("email");
  }

  validateField(field) {
    let _user = this.state.user;

    switch (field) {
      case "email":
        if (_user.email == null)
          return "Email invalid";
        let isValid = this.validateEmail(_user.email);
        if (isValid)
          return null;
        else
          return "Email invalid"
        break;
      case "password":
        if (_user.password != null && _user.password.length > 8)
          return null;
        else
          return "Length needs to be higher than 8";
        break;
    }
    return null;
  }

  async login() {
    if (this.isFormValid()) {

      this.setState({ loading: true });

      const response = await fetch('api/Auth/Login', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(this.state.user)
      });

      const data = await response.json();

      if (response.status != 200) {
        console.error('HTTP response code status->' + response.status + ' message->' + data);
        this.setState({ loading: false });
        this.setSnackBarMessage(true, true, data);
        return;
      }
     
      saveToken(data);
      this.setState({ loading: false });
      
      if(isAdmin()) {
        window.location.href = '/admin';
      }
      else {
        window.location.href = '/';
      }
      //this.setSnackBarMessage(true, false, "You have succesfully registered!");
    }
  }
  
 
  render() {
    var {
      user,
      loading,
      showPassword,
      openSnackBar,
      snackBarMsg,
      snackBarType
    } = this.state;

    let contents =
      <div className={styles.loginContainer}>
        <div className={styles.loginFormContainer}>
          <div className={styles.loginFormCompanyName}>
            <span className={styles.loginformCompanyNameDot}></span>
            <h4 className={styles.logincompanyName}>Spaces by BrandLab360</h4>
          </div>
          <div className={styles.loginform}>
            <h2 className={styles.loginformTitle}>Welcome back!</h2>
            <div className={styles.loginformText}>Welcome back! Please enter your details.</div>

            <div className={"m-b-20"}>
              <div className={styles.loginformRowItem}>
                <label htmlFor="email">Email</label>
                <InputBase
                  id="email"
                  name="email"
                  sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                  className={styles.loginformTextField}
                  type="text"
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => this.handleChange(e.target.value, "email")}
                  value={user.email} />
                {user.email && <p className={styles.loginerrorMsg}>{this.validateField("email")}</p>}
              </div>
              <div className={styles.loginformRowItem}>
                <label htmlFor="password">Password</label>
                <InputBase
                  id="password"
                  name="password"
                  sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                  className={styles.loginformTextField}
                  type={showPassword ? 'text' : 'password'}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => this.handleChange(e.target.value, "password")}
                  value={user.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        sx={{ marginRight: '0px' }}
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  } />
                {user.password && user.password.length <= 8 && <p className={styles.loginerrorMsg}>{this.validateField("password")}</p>}
              </div>
            </div>
            <div className={styles.loginRememberContainer}>
              <Checkbox
                checked={user.isRememberMe}
                className={styles.loginFormCheckbox}
                onChange={(e) => { user.isRememberMe = e.target.checked; this.setState({ user: user }) }}
                size="small"
                sx={{
                  marginLeft: "-9px",
                  color: "#2f125e",
                  '&.Mui-checked': {
                    color: "#2f125e",
                  },
                }} />
              Remember me
              <a className={styles.loginForgotPassword} >Forgot password?</a>
            </div>
            <div className={this.isFormValid() ? styles.loginformButton : styles.loginformButtonDisabled} onClick={() => this.login()}>Sign in</div>
            <div className={styles.loginHaveNotAccount}>Don't have an account? &nbsp; <a href="/register"> Sign up</a></div>
          </div>
          <div className={styles.leftCompanyName}>&copy; BrandLab360 2023</div>
        </div>
        <div className={styles.loginLogoContainer}>
          <div className={styles.loginbcgLogo} style={{ backgroundImage: 'url("./images/logo/Logo.png")' }}></div>
        </div>
      </div>;

    return <>
      {loading ?
        <div className='loader'>
          <CircularProgress color="secondary" />
        </div>
        : ""}
      {contents}
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={this.handleClose}>
        <Alert onClose={this.handleClose} severity={snackBarType} sx={{ width: '100%' }}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>;
  }


}
