
import { Component } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import styles from "./../CSSModules/Popups/Feedback.module.scss";
import { CircularProgress, DialogContent, Rating, Slide, TextareaAutosize } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6219D3",
      marginLeft: "0px !important",
      fontSize: 16,
      borderRadius: "16px",
      padding: "3px 10px"
    },
  }));

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFC84E',
    },
    '& .MuiRating-iconHover': {
      color: '#FFC84E',
    },
  });

export const FeedbackDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {        
        backgroundColor: "#F4F6FC",
        color: "black",       
        maxHeight: "90%",
        borderRadius: "16px",
        overflowX: "hidden"
    }
}));

export function FeedbackDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ padding: "25px 0px", fontFamily: 'Cosmata Bold', textAlign: "center", backgroundColor: "#F4F6FC", color: "black" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


export class Feedback extends Component {

    constructor() {
        super();
        this.state = {
            name: "Feedback",
            open: false,
            bugReportOpen: false,
            rating: null,
            bugMessage: "",
            loading: false,
            wentWrongMsg: "",
            doingMessage: "",
            happenedMsg: "",
            improvedMsg: ""
        };
    }

    componentWillUnmount() { 
        this.setState({email: "", bugMessage: ""});        
    }

    focusInput = (e) => window.unity.SendMessage('bloodbridge', "disablekeycapture", "");

    blurInput = (e) => window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");    

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, bugMessage: "", rating: 0 });
    };

    handleBugReportClose = () => {
        this.setState({ bugReportOpen: false, wentWrongMsg: "",
        doingMessage: "",
        happenedMsg: "",
        improvedMsg: "" });
    };

    async sendBugReport() {
        this.setState({loading: true});

        const message = (this.state.wentWrongMsg ? ("What went wrong?\n" 
            + this.state.wentWrongMsg + "\n") : "")
            + (this.state.doingMessage ? ("What were you doing when the problem happened?\n"
            + this.state.doingMessage + "\n") : "")
            + (this.state.happenedMsg ? ("Where were you when it happened?\n"
            + this.state.happenedMsg + "\n") : "")
            + (this.state.improvedMsg ? ("How could it be improved?\n"
            + this.state.improvedMsg) : "");


        console.log(message);
        const response = await fetch(`api/Auth/SendBugReport`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({message: message})
        });

        if (response.status != 200) {
            console.error('HTTP response code status->' + response.status);           
        }

        this.setState({loading: false});
        this.handleBugReportClose();
    }

    async sendFeedback() {
        this.setState({loading: true});

        const response = await fetch(`api/Auth/GiveFeedback`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({message: this.state.bugMessage, rating: this.state.rating})
        });

        if (response.status != 200) {
            console.error('HTTP response code status->' + response.status);           
        }

        this.setState({loading: false});
        this.handleClose();
    }

    renderFeedback() {
        var { open, bugMessage, rating } = this.state;

        return <FeedbackDialog className="feedbackDialog"
            sx={{ '& .MuiPaper-root': { bgcolor: "#F4F6FC", borderRadius: "16px", maxWidth: "420px", padding: "0", } }}
            maxWidth={"420px"}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description">
            <FeedbackDialogTitle onClose={this.handleClose}></FeedbackDialogTitle>
            <DialogContent>
                <h5 className={styles.feedbackTitle}>Give feedback</h5>
                <div className={styles.feedbackDescription}>
                    You're one of the first people in the world to try 3D shopping! What do you think of SPAC3S.IO?
                </div>

                <div className={styles.ratingContainer}>
                    <StyledRating name="size-large"
                        value={rating}
                        sx={{
                            fontSize: "4rem",
                            "& label": {
                                margin: '0px'
                            },
                            "& .MuiRating-icon": {
                                width: '3.5rem'
                            }
                        }}
                        onChange={(event, newValue) => {
                            this.setState({ rating: newValue });
                        }}
                        icon={<img className={styles.feedbackRatingIcon} src="./images/icons/Star.png" />}
                        emptyIcon={<img className={styles.feedbackRatingEmptyIcon} src="./images/icons/StarEmpty.png" />}
                    />
                </div>

                <div className={styles.feedbackDescription}>
                    Do you have any thoughts you’d like to share?
                </div>
                <TextareaAutosize
                    minRows={3}
                    maxRows={3}
                    className={styles.feedbackTextField}
                    aria-label="message"
                    onFocus={(e) => this.focusInput(e)}
                    onBlur={(e) => this.blurInput(e)}
                    onChange={(e) => { this.setState({ bugMessage: e.target.value }) }}
                    value={bugMessage} />

                <div className={styles.actionButton}>
                    <div className={styles.actionCancelButton} onClick={() => this.handleClose()}>Maybe later</div>
                    <div className={styles.actionSubmitButton} onClick={() => this.sendFeedback()}>Submit</div>
                </div>
                <div className={styles.reportButton} onClick={() => { this.setState({bugReportOpen: true}); this.handleClose(); }}>Report an issue</div>

            </DialogContent>
        </FeedbackDialog>;
    }

    renderBugReport() {
        var { bugReportOpen, wentWrongMsg, doingMessage, happenedMsg, improvedMsg } = this.state;

        return <FeedbackDialog className={styles.bugReportDialog}
            sx={{ '& .MuiPaper-root': { bgcolor: "#F4F6FC", borderRadius: "16px", maxWidth: "600px", padding: "0", } }}
            maxWidth={"600px"}
            open={bugReportOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleBugReportClose}
            aria-describedby="alert-dialog-slide-description">
            <FeedbackDialogTitle onClose={this.handleBugReportClose}></FeedbackDialogTitle>
            <DialogContent>
                <h5 className={styles.feedbackTitle}>Report an issue</h5>
                <div className={styles.feedbackDescription}>
                    What went wrong?
                </div>
                <TextareaAutosize
                    minRows={1}
                    maxRows={1}
                    className={styles.feedbackTextField}
                    aria-label="message"
                    onFocus={(e) => this.focusInput(e)}
                    onBlur={(e) => this.blurInput(e)}
                    onChange={(e) => { this.setState({ wentWrongMsg: e.target.value }) }}
                    value={wentWrongMsg} />

                <div className={styles.feedbackDescription}>
                    What were you doing when the problem happened?
                </div>
                <TextareaAutosize
                    minRows={1}
                    maxRows={1}
                    className={styles.feedbackTextField}
                    aria-label="message"
                    onFocus={(e) => this.focusInput(e)}
                    onBlur={(e) => this.blurInput(e)}
                    onChange={(e) => { this.setState({ doingMessage: e.target.value }) }}
                    value={doingMessage} />

                <div className={styles.feedbackDescription}>
                    Where were you when it happened?
                </div>
                <TextareaAutosize
                    minRows={1}
                    maxRows={1}
                    className={styles.feedbackTextField}
                    aria-label="message"
                    onFocus={(e) => this.focusInput(e)}
                    onBlur={(e) => this.blurInput(e)}
                    onChange={(e) => { this.setState({ happenedMsg: e.target.value }) }}
                    value={happenedMsg} />

                <div className={styles.feedbackDescription}>
                    How could it be improved?
                </div>
                <TextareaAutosize
                    minRows={1}
                    maxRows={1}
                    className={styles.feedbackTextField}
                    aria-label="message"
                    onFocus={(e) => this.focusInput(e)}
                    onBlur={(e) => this.blurInput(e)}
                    onChange={(e) => { this.setState({ improvedMsg: e.target.value }) }}
                    value={improvedMsg} />

                <div className={styles.actionButton}>
                    <div className={styles.actionCancelButton} onClick={() => this.handleBugReportClose()}>Cancel</div>
                    <div className={styles.actionSubmitButton} onClick={() => this.sendBugReport()}>Submit</div>
                </div>

            </DialogContent>
        </FeedbackDialog>;
    }

    render() {

        var { loading } = this.state;

        return (<>
            {loading &&
                <div className='loader'>
                    <CircularProgress color="secondary" />
                </div>
            }
            <BootstrapTooltip key="RateandReview" title="Rate and Review" placement="top" >
                <div className={styles.feedbackIconContainer} onClick={() => this.handleClickOpen()}>
                    <img className={styles.feedbackIcon} src="./images/icons/Feedback icon.png" />
                </div>
            </BootstrapTooltip>
            {this.renderFeedback()}
            {this.renderBugReport()}
        </>
        )
    }
}