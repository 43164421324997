import { Component } from "react";
import styles from "./../CSSModules/WallArtView.module.css";
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center'
    },
}));

export class WallArtView extends Component {

    constructor() {
        super();
        this.state = {
            name: "Wall Art",
            hide: false,            
            loading: true,
            artUrl: "",            
            artKey: "artUrl",
            dragArtUrl: null,
            artsKey: "arts",
            arts: [
                {id:0, isCustomUrl: false, artUrl: ""},
                {id:0, isCustomUrl: false, artUrl: ""},
                {id:0, isCustomUrl: false, artUrl: ""},
                {id:0, isCustomUrl: false, artUrl: ""},
                {id:0, isCustomUrl: false, artUrl: ""},
                {id:0, isCustomUrl: false, artUrl: ""}]
        };
        
        window.addEventListener('mouseup', this.clickUp);
    }

    clickUp = (e) => {        
        if (window.unityMounted) {
            window.unity.SendMessage('bloodbridge', "itemdragend", "");

            if (this.state.dragArtUrl) {
                this.setState({ dragArtUrl: null });
                this.setState({ hide: false });
            }
        }
    }

    componentDidMount() {      
        this.getMyArts();
    }

    async getMyArts(){
        this.setState({loading:true});
        const response = await fetch('api/Art/GetMyArts');
        const data = await response.json();

        if(response.status != 200) {
            console.error(data);
            this.setState({loading:false});
        }
        else {
            var arts = this.state.arts;

            data.forEach(el => {
                var art = {id: el.id, isCustomUrl: !el.artUrl.includes('/Files/Arts/'), artUrl: el.artUrl};
                arts[el.orderIndex] = art;
            });

            this.setState({arts: arts, loading:false});
        }
    }

    componentWillUnmount() {        
        
    }

    focusInput(e){
        window.unity.SendMessage('bloodbridge', "disablekeycapture", "");
    }

    blurInput(e, item, i){        
        window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");
        if(this.isImageInUrl(item.artUrl)) {
            this.uploadArtFileToServer(null, item, i, item.artUrl);
        }
    }

    isImageInUrl(url) {
        if (!url || url.length <= 0)
          return null;
    
        var filename = url.split('/').pop();
        if (filename.toLowerCase().indexOf(".jpg") >-1 || filename.toLowerCase().indexOf(".jpeg")>-1 || filename.toLowerCase().indexOf(".png")>-1)
          return filename;
    
        return null;
    }

    setArtValue(value, i, isCustom) {
        let arts = this.state.arts;
        let currentArt = arts[i];
        currentArt.isCustomUrl = isCustom;
        currentArt.artUrl = value;
        arts[i] = currentArt;
        
        this.setState({ arts: arts });       
       //save Art Here
    }

    async deleteArt(item, index) {

        if(item.id && item.artUrl.length > 0) {
            this.setArtValue("", index, false);
            await fetch(`api/Art/DeleteArt/${item.id}`, { method: 'DELETE' });            
        }
    }

    uploadArt(e, item, index) {
        let file = document.getElementById("uploadart"+index).files[0];
        if (file) {
            if (file.size > 10485760) {
                alert("File is too big!");
                filesValid = false;
                console.log("Files over 10mb size");
                return;
            }
            else {
               this.uploadArtFileToServer(file, item, index, "");
            }
        }
    }
    
    async uploadArtFileToServer(file, item, i, url) {
        
        const form = new FormData();

        if(file) {
           form.append("file", file);
        } else if(url && url.length>0) {
           form.append("artUrl", url);
        }
        else {
            return;
        }

        form.append("id", item.id);
        form.append("orderIndex", i);

        const response = await fetch('api/art/UploadArt', {
            method: 'POST',           
            body: form
        });

        const data = await response.json();

        if (response.status != 200) {
            console.error('HTTP response code = '+response.status +' message='+ data.message);
            return;
        }
        else {
            var arts = this.state.arts;
            var art = {id: data.id, isCustomUrl: !data.artUrl.includes('/Files/Arts'), artUrl: data.artUrl};
            arts[i] = art;
            this.setState({arts: arts});
        }
    }

    renderArtItem(item, i) {
        return (
            <section key={i} className={styles.artSection}>
                <div className={styles.artItemContainer}>
                    <div onDragStart={() => this.onStart(item)} className={styles.art} onClick={() => document.getElementById("uploadart" + i).click()}>
                        {item.artUrl
                            ? (<img className={styles.artImage}
                                src={item.artUrl +"?t="+new Date().getTime()}
                                alt={"artImage" + i}
                                loading="lazy" />)
                            : (<img className={styles.artIcon}
                                src="./images/icons/picture_upload_black.png"
                                alt={"artImage" + i}
                                loading="lazy" />)
                        }

                        <input hidden type="file" id={"uploadart" + i} accept="image/png, image/jpg, image/jpeg" name="artFile" onChange={(e) => this.uploadArt(e, item, i)}></input>
                    </div>

                    <div className={styles.artUrlContainer}>
                        <label className={styles.artUrlContainerLabel}> Or upload from a URL</label>
                        <div className={styles.artUrlInputContainer}>
                            <InputBase
                                placeholder="Enter image URL"
                                sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                                className={styles.artUrlTextField}
                                type="text"
                                variant="filled"
                                fullWidth
                                size="small"
                                onFocus={(e) => this.focusInput(e)}
                                onBlur={(e) => this.blurInput(e, item, i)}
                                onChange={(e) => this.setArtValue(e.target.value, i, true)}
                                value={item.isCustomUrl ? item.artUrl : ""} />

                            {item.artUrl ?
                                <div className={styles.iconArtContainer} onClick={() => this.deleteArt(item, i)}>
                                    <CloseIcon sx={{ color: "white", width: "15px", height: "15px" }} />
                                </div> : ""}
                        </div>
                    </div>
                </div>
            </section>);
    }

    render() {
        var { hide, arts } = this.state;
        var tutorialData = this.props.tutorialData;

        let contents = this.state.loading
            ? <p className={styles.artLoading}><em>Loading...</em></p>
            : <BootstrapTooltip
                key={"myProductsViewAdd"}
                open={tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 2 && !hide}
                placement="right"
                title={"Wall art is good way to show off your brand and favourite products. Upload your wall art or enter image URL!"}>
                <div>{arts.map((item, i) => this.renderArtItem(item, i))}</div>
            </BootstrapTooltip>

        return (
            <div className={hide ? styles.wallartHide : styles.wallart}>
                <h4 className={styles.artTitle}>Branding Wall Art</h4>
                {contents}
            </div>
        )
    }

     /*DnD */
     onStart(item) {
        if (this.state.dragArtUrl != null || !item.artUrl) {
            return;
        }
        
        this.setState({hide: true});

        this.setState({ dragArtUrl: item });
        console.log("start Art Drag -> " + item.artUrl);

        if (window.unityMounted) {
            window.unity.SendMessage('bloodbridge', "enablekeycapture", "");
            window.unity.SendMessage('bloodbridge', "picturedragstart", item.artUrl);
        }       

        document.getElementById("unity-canvas").focus();
        document.getElementById("unity-canvas").click();
    };
}