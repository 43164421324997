export class WebUserModel{
    constructor(
        id,
        storeName,        
        themeId,
        sizeId,
        logoUrl,
        floorMaterialId,
        wallColor,
        accentsColor) {
      this.id = id;
      this.storeName = storeName;
      this.themeId = themeId;
      this.sizeId = sizeId;
      this.logoUrl = logoUrl;
      this.floorMaterialId = floorMaterialId;
      this.wallColor = wallColor;
      this.accentsColor = accentsColor;
    }
}

export class UnityUserModel{
  constructor(          
      themeId,
      sizeId,
      logoUrl,
      floorMaterialId,
      wallColor,
      accentsColor, 
      isEditorVersion) {

    this.themeId = themeId;
    this.sizeId = sizeId;
    this.logoUrl = logoUrl;
    this.floorMaterialId = floorMaterialId;
    this.wallColor = wallColor;
    this.accentsColor = accentsColor;
    this.isEditorVersion = isEditorVersion;
  }
}

export function webModelToUnityModel(model, isEditorVersion = false){
  return new UnityUserModel(model.themeId,
    model.sizeId,
    model.logoUrl,
    model.floorMaterialId,
    model.wallColor,
    model.accentsColor, 
    isEditorVersion);
}