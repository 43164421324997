import fetchIntercept from 'fetch-intercept';
import { getToken, logout } from './HandleJWT';

export const AuthInterceptor = () => {
    fetchIntercept.register({
        request: function (url, config) {
            // Modify the url or config here           
            if (url && url.indexOf("api") > -1) {                
                config = config || {};
                const headers = config.headers || {};

                config.headers = {
                    ...headers,                    
                    Authorization: `Bearer ${getToken()}`
                };                
            }
            return [url, config];
        },

        requestError: function (error) {
            // Called when an error occured during another 'request' interceptor call           
            return Promise.reject(error);
        },

        response: function (response) {
            // Modify the reponse object            
            if(response.status === 401) {
                logout();
                return response;
            }
            else if(response.status === 403 && response.url && response.url.indexOf("/api/") > -1) {                 
                logout();
                return response;
            }

            return response;
        },

        responseError: function (error) {
            // Handle an fetch error            
            return Promise.reject(error);
        },
    });
};