export const assistensChatBotData = [
    {
        id: 1,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Assistant/1.wav",
        helpText: "How do I order?",
        helpDescription: "You can buy an item or place it in your cart by selecting product details for the item you want to buy. You will see the option to add to cart. You can carry on shopping or complete your purchase on the checkout page."
    },
    {
        id: 2,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Assistant/2.wav",
        helpText: "How do I leave the virtual store?",
        helpDescription: "You can leave the store by simply closing the tab on your browser."
    },
    {
        id: 3,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Assistant/3.wav",
        helpText: "I don’t see an answer to my question here",
        helpDescription: "You can access our full FAQs or help pages on our website"
    }
]