export const builderChatBotData = [
    {
        id: 1,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/1.wav",
        helpText: "Help with store size",
        helpDescription: "Let’s choose your store size! To do this click on the my store symbol and pick your desired size. You can always upgrade later if you need more space"
    },
    {
        id: 2,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/2.wav",
        helpText: "Help with store style/theme",
        helpDescription: "to pick the theme or style of your store click on the store theme symbol, and choose your theme"
    },
    {
        id: 3,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/3.wav",
        helpText: "Help with my logo",
        helpDescription: "Add your logo. If you don’t have one you can simply skip this step. Click on the my store symbol, set your store name and upload your logo"
    },
    {
        id: 4,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/4.wav",
        helpText: "Help with wall art",
        helpDescription: "Wall art is a great way to brand your space. Just click on the wall art symbol and upload your wall art images. Once uploaded you can place it around the store in your desired position by simply clicking on the image and dragging it"
    },
    {
        id: 5,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/5.wav",
        helpText: "Help with uploading products",
        helpDescription: "Time to stock your store! Click on the my products symbol. You can now upload your product images. Images need any background removed... but I can help you with that. Just click the remove background option if you need it. Once your product image is uploaded you can enter your product information in the dialogue box. Most importantly don’t forget to add a link to the product page on your website so customers can purchase your product. You can just copy and paste this straight into the link box. When you’re done, simply drag your product into position on a rail or shelf"
    },
    {
        id: 6,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/6.wav",
        helpText: "Help with placing product rails, shelves or furniture",
        helpDescription: "Let’s add some clothes rails or shelves to display your products! Click the add fixtures symbol in the side menu. Simply pick your rail, shelf or other available furniture from the correct category and drag it into the store. Once it’s placed in the store click on the item and you will see a small movement symbol pop up allowing you to drag the item into position. For wall rails you can click the upper arrow on the movement symbol and drag the rail up or down the wall to place it. Click the item once more to confirm its position. Bear in mind, customers can’t walk through rails or furniture, so don’t block their path!"
    },
    {
        id: 7,
        audioUrl: "https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Builder/7.wav",
        helpText: "Help with making my store live",
        helpDescription: "Congratulations - your store is created! You can come back here anytime to make changes or add updates. Are you ready to open? Click on the publish symbol, and your store will go live! Just copy the link provided and paste it into a logo or hyperlink it somewhere on your website for your customers to click through. I’ll be there to welcome them and act as their personal sales assistant while they shop!"
    }
]