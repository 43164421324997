
import { Component } from "react";
import { styled } from '@mui/material/styles';
import * as React from 'react';
import styles from "./../CSSModules/Popups/CameraSwitch.module.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AvatarsDialog } from "./AvatarsDialog";



const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6219D3",
      marginLeft: "0px !important",
      fontSize: 16,
      borderRadius: "16px",
      padding: "3px 10px"
    },
  }));

export class CameraSwitch extends Component {

    constructor() {
        super();
        this.state = {
            name: "CameraSwitch",
            open: false,           
            loading: false,
            isThPMCamera: false,
            avatarId: -1,
            isFirstTime: true,
            avatarKey: "avatar"
        };
    }

    componentDidMount() {
        if(localStorage.getItem(this.state.avatarKey)) {
            this.setState({avatarId: +localStorage.getItem(this.state.avatarKey)});
        }
        else if(this.props.openAvatarSelection) {
            this.setState({ isFirstTime: false });
        }
    }

    componentWillUnmount() { }

    switchCameraMode(isThPMCamera) {
        var { isFirstTime, avatarId } = this.state;

        if (isFirstTime && avatarId === -1) {
            this.setState({ isFirstTime: false });
        }
        else if(!this.props.roomName && isFirstTime && avatarId > -1) {
            window.unity.SendMessage('bloodbridge', "changeavatar", avatarId);
            this.setState({ isThPMCamera: isThPMCamera, isFirstTime: false });
            window.unity.SendMessage('bloodbridge', "changecamera", isThPMCamera ? 0 : 1);
        }
        else {
            this.setState({ isThPMCamera: isThPMCamera });
            window.unity.SendMessage('bloodbridge', "changecamera", isThPMCamera ? 0 : 1);
        }
    }

    onAvatarChange(avatarId) {
        var { isThPMCamera } = this.state;

        console.log(avatarId);
        if(this.props.openAvatarSelection) {
            localStorage.setItem(this.state.avatarKey, avatarId);
            this.setState({avatarId: avatarId});
            this.props.onAvatarChange(avatarId);           
        }
        else {
            localStorage.setItem(this.state.avatarKey, avatarId);
            this.setState({avatarId: avatarId, isThPMCamera: !isThPMCamera});
            window.unity.SendMessage('bloodbridge', "changeavatar", avatarId);
            window.unity.SendMessage('bloodbridge', "changecamera", !isThPMCamera ? 0 : 1);
        }
    }

    render() {

        var { isThPMCamera, isFirstTime, avatarId } = this.state;

        return (<>
        <div className={styles.cameraModesContainer}>
            <BootstrapTooltip key="ThirdPersonMode" title="Third Person Mode" placement="top" >
                <div className={!isThPMCamera ? styles.cameraIconContainer : styles.cameraIconContainerActive } onClick={() => this.switchCameraMode(true)}>
                    <img className={styles.cameraIcon} src="./images/icons/thp_camera_icon.png" />
                </div>
            </BootstrapTooltip>       
            <BootstrapTooltip key="FirstPersonMode" title="First Person Mode" placement="top" >
                <div className={isThPMCamera ? styles.cameraIconContainer : styles.cameraIconContainerActive} onClick={() => this.switchCameraMode(false)}>
                    <img className={styles.cameraIcon} src="./images/icons/fp_camera_icon.png" />
                </div>
            </BootstrapTooltip>       
        </div>
        { !isFirstTime && avatarId === -1 && <AvatarsDialog onAvatarChange={(avatarId)=>this.onAvatarChange(avatarId)}/> }
        </>)
    }
}