import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styles from "./../CSSModules/ChatBot.module.css";
import React, { Component } from 'react';
import { assistensChatBotData } from '../../lib/assistensChatBotData';

export class AssistensChatBot extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "ChatBot",
            open: false,
            selectedId: 0,
            isStart: true,
            isPlay: false,
        };

        this.audio = null;
    }

    componentDidMount() {
        this.audio = new Audio("https://ccstore.s3.eu-west-2.amazonaws.com/Shared/ChatBotAudio/Assistant/0.wav");
        this.audio.addEventListener('ended', () => {
            this.setState({ isPlay: false });
            window.unity.SendMessage('bloodbridge', "disableavatartalk", "");
        });
    }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({ isPlay: false }));
    }

    playIntro() {
        
    }

    playAudio() {
        this.setState({ isPlay: true }, () => {
            this.audio.play();
        });

        window.unity.SendMessage('bloodbridge', "enableavatartalk", "");
    }

    pauseAudio() {
        this.setState({ isPlay: false }, () => {
            this.audio.pause();
        });

        window.unity.SendMessage('bloodbridge', "disableavatartalk", "");       
    }

    setAudioSrc(audioUrl) {
        this.audio.src = audioUrl;
        this.audio.load();
        this.playAudio();
    }


    toggleOpen = () => {
        this.setState({ isStart: this.state.open ? false : this.state.isStart, open: !this.state.open, selectedId: !this.state.open ? 0 : this.state.selectedId });
        if(this.state.isStart && !this.state.open) {
            this.playAudio();
          }
          else {
            this.pauseAudio();
          }
    }

    closeMenu = () => {
        this.setState({ selectedId: 0 });
        this.pauseAudio();
    }

    setSelected(item) {
        this.setState({ selectedId: item.id });
        this.setAudioSrc(item.audioUrl);
    }

    setPrevious() {
        this.setState({ selectedId: 0 });
        this.pauseAudio();
      }
    
      continue() {
        this.setState({isStart: false});
        this.pauseAudio();
      }

    renderQuestions() {
        var { selectedId } = this.state;

        return <>
            <div key={"answer-0"} className={styles.chatBotAnswer}>What would you like help with?</div>
            {assistensChatBotData.map(item => {
                return <React.Fragment key={item.id}>
                    <div className={selectedId === item.id || selectedId === 0 ? styles.chatBotQuestion : styles.hideChatBotQuestion}
                        onClick={() => this.setSelected(item)}>
                        {item.helpText}
                    </div>
                    {selectedId === item.id && <div key={"answer-" + item.id} className={styles.chatBotAnswer}>{item.helpDescription}</div>}
                </React.Fragment>;
            })}
        </>;
    }

    render() {
        var { open, isStart, selectedId } = this.state;

        let contents = isStart
            ? <React.Fragment key="preview">
                <div className={styles.chatPreviewText}>Hello and welcome to our 3D store! <br />
                    My name’s Ayva and I’ll be your assistant today.<br /><br />
                    You can navigate the store using the joystick controller on your mobile screen, or
                    using the arrows on your keyboard.<br /><br />
                    When you see a product you like simply click the tag to see detailed product information.
                    If you need some help while you’re here just click the shop assistant button and I’ll be right with you!
                </div>
                <div className={styles.chatbotContinueButton} onClick={() => this.continue()}>
                    Continue
                    <ArrowForwardIosIcon sx={{ color: "white", width: "18px", height: "18px" }} />
                </div>
            </React.Fragment>
            : !open ? "" : this.renderQuestions();

        return (
            <div className={(open ? styles.chatbotOpen : styles.chatbot)}>
                <div className={styles.chatBotImgContainer + (isStart && !open ? " blinking" : "")} onClick={() => this.toggleOpen()}>
                    <img className={styles.chatBotImg} src="./images/icons/chat_ic.png" />
                </div>
                <div className={styles.chatBotContainer}>
                    <div className={styles.chatbotCloseButton} onClick={() => this.toggleOpen()}>
                        <CloseIcon sx={{ color: "white", width: "24px", height: "24px" }} />
                    </div>
                    {selectedId > 0
                        ? <div className={styles.chatbotPrevButton} onClick={() => this.setPrevious()}>
                            <ArrowBackIosNewIcon sx={{ color: "white", width: "20px", height: "20px" }} />
                        </div>
                        : ""}
                    <div className={styles.helpTextContainer}>
                        {contents}
                    </div>
                </div>
            </div>
        )
    }
}