
import { Component } from "react";
import { CustomDialog, CustomDialogTitle } from "./CustomDialog"
import * as React from 'react';
import styles from "./../CSSModules/Popups/PromoteStorePopup.module.css";
import { DialogContent, InputBase, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class PromoteStore extends Component {

    constructor() {
        super();
        this.state = {
            name: "PromoteStore",
            open: true,
            embedLink: '<iframe src="https://..." title="Visit my store on Spac3s.io" width="1920" height="1080"></iframe>',
            shareLink: "https://...",
        };
    }

    componentDidMount() {
        this.getShareLink();
    }

    async getShareLink() {

        const response = await fetch("api/Account/GetPublishStoreLink");
        const data = await response.json();

        if (response.status != 200) {
            console.error(response.status);
            console.error(response);
        }
        else {
            if (data) {
                this.setState({
                    shareLink: (window.location.origin + data),
                    embedLink: `<iframe src="${window.location.origin + data}" title="Visit my store on Spac3s.io" width="1920" height="1080"></iframe>`
                });
            }
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.onPopupClose();
    };

    downloadButton = () => {        
       // var imgUrl = window.location.origin+ "/images/logo/Visit our 3d Store Button.png";
       // window.open(imgUrl, '_self');
    };

    render() {

        var { open, embedLink, shareLink } = this.state;

        return (
            <CustomDialog className={styles.promoteStoreDialog}
                maxWidth={"710px"}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-describedby="alert-dialog-slide-description">
                <CustomDialogTitle>{"Promote your 3D Store!"}</CustomDialogTitle>
                <DialogContent>
                    <div className={styles.promoteLabel}>
                        You can embed your 3D on your existing eCommerce website by using the html link below:
                    </div>
                    <div className={styles.promoteInputContainer}>
                        <InputBase
                            placeholder="embed html link"
                            sx={{ fontFamily: "Cosmata", pt: "5px" }}
                            className={styles.promoteUrlTextField}
                            type="text"
                            variant="filled"
                            fullWidth
                            disabled
                            size="small"
                            value={embedLink} />
                        <div className={styles.promoteCopyButton} onClick={() => navigator.clipboard.writeText(embedLink)}>Copy</div>
                    </div>
                    <div className={styles.promoteLabel}>
                        Or copy your store URL link to share on social media platforms:
                    </div>
                    <div className={styles.promoteInputContainer}>
                        <InputBase
                            placeholder="embed html link"
                            sx={{ fontFamily: "Cosmata", pt: "5px" }}
                            className={styles.promoteUrlTextField}
                            type="text"
                            variant="filled"
                            fullWidth
                            disabled
                            size="small"
                            value={shareLink} />
                        <div className={styles.promoteCopyButton} onClick={() => navigator.clipboard.writeText(shareLink)}>Copy</div>
                    </div>
                    <div className={styles.promoteLabel}>
                        Or download our 3D store button to add to your website:
                    </div>
                    <div className={styles.promoteInputContainer}>
                        <img className={styles.promoteSpacesButton}
                            src="./images/logo/Visit our 3d Store Button_SMALL.png"
                            alt="promoteButton" />
                        <a className={styles.promoteDownloadButton} href="./images/logo/Visit our 3d Store Button.png" download="3D store Button">Download</a>
                    </div>

                </DialogContent>
            </CustomDialog>
        )
    }
}