export const actionData = [
    {
        id: 7,
        icon: "./images/icons/undo.png",
        text: "Undo"
    },
    {
        id: 8,
        icon: "./images/icons/redo.png",
        text: "Redo"
    },
    {
        id: 9,
        icon: "./images/icons/delete.png",
        text: "Delete"
    },
    {
        id: 10,
        icon: "./images/icons/user.png",
        text: "Settings"
    }
]