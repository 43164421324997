import { Alert, Snackbar } from "@mui/material";
import { Component } from "react";
import styles from "./../CSSModules/PublishView.module.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center',
        marginLeft: '30px!important'
    },
}));

export class PublishView extends Component {

    constructor() {
        super();
        this.state = {
            name: "Publish",
            shareLink: "https://...",
            openSnackBar: false,
            snackBarMsg: "",
            origin: ""
        };
    }

    componentDidMount() {
        this.setState({origin: window.location.origin, shareLink: (window.location.origin+"/")})
        this.getShareLink();
        
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackBar: false });
    };

    async getShareLink() {
        
        const response = await fetch("api/Account/GetPublishStoreLink");
        const data = await response.json();

        if (response.status != 200) {            
            console.log(data);
            console.log(response.status);
            console.log(response);
        }
        else {
            if(data) {
                this.setState({shareLink: (this.state.origin+data)});
            }
        }
    }

    async publishStore() {
        
        const response = await fetch("api/Account/PublishStore");
        const data = await response.json();

        if (response.status != 200) {
            if(response.status === 404) {
                this.setState({snackBarMsg: data, openSnackBar: true});
            }            
            console.log(response);
        }
        else {
            if(data) {
                this.setState({shareLink: (this.state.origin + data.url)});
                this.props.storePublished(data.storeId);
            }
        }
    }

    render() {
        var { openSnackBar, snackBarMsg } = this.state;

        var tutorialData = this.props.tutorialData;

        return (<>
            <div className={styles.publish}>
                <h4>Publish</h4>
                <div className={styles.publishDescription}>Ready to publish your store?</div>
                <BootstrapTooltip
                    key={"publishView"}
                    open={tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 4}
                    className={styles.publishTooltip}
                    placement="right"
                    title={"Click on the publish button and your store will now be live!"}>
                    <div className={styles.publishButton} onClick={() => { this.publishStore() }}>Publish</div>
                </BootstrapTooltip>

                <h4>Share</h4>
                <div className={styles.publishDescription}>Share your store on social media using the link below. You can also embed your store on your website with the same link.</div>
                <BootstrapTooltip
                    key={"publishViewCopy"}
                    open={tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 4}                    
                    placement="right"
                    title={"Just copy the link provided and share with your customers. I’ll be there to welcome them and act as their personal sales assistant while they shop!"}>
                    <div className={styles.linkContainer}>
                        <div className={styles.shareLink}>{this.state.shareLink}</div>
                        <div className={styles.copyButton} onClick={() => navigator.clipboard.writeText(this.state.shareLink)}>Copy</div>
                    </div>
                </BootstrapTooltip>
            </div>
            <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>)
    }
}