const tokenKey = "accesToken";
const expirationKey = "toke-expiration";
const isStoreExistKey = "is-store";
const isAdminKey = "isAdmin";
 
export function saveToken(data) {
    localStorage.setItem(tokenKey, data.accessToken);
    localStorage.setItem(expirationKey, data.expiration);
    localStorage.setItem(isStoreExistKey, data.isStoreExist);
    localStorage.setItem(isAdminKey, data.isAdmin);
}

export function isUserAuth() {
    const token = localStorage.getItem(tokenKey);
    if(!token) {
        return false;
    }

    const expiration = localStorage.getItem(expirationKey);
    const expDate = new Date(expiration);

    if(expDate <= new Date()) {
        logout();
        return false;
    }

    return true;
}

export function isAdmin() {
    const isAdmin = localStorage.getItem(isAdminKey) === 'true';
    return isAdmin;
}

export function saveStoreExist() {    
    localStorage.setItem(isStoreExistKey, true);
}

export function isStoreExist() {
    const isExist = localStorage.getItem(isStoreExistKey) === 'true'; 
    return isExist;
}

export function getToken() {
    const token = localStorage.getItem(tokenKey); 
    return token;
}

export function clearLocalStorage() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expirationKey);
    localStorage.removeItem(isStoreExistKey);
    localStorage.removeItem(isAdminKey);
}

export function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expirationKey);
    localStorage.removeItem(isStoreExistKey);
    localStorage.removeItem(isAdminKey);
    window.location.href = '/';
}