export const colorData = [
    {
        colorId: "#ffebee"       
    },
    {
        colorId: "#ffcdd2"       
    },
    {
        colorId: "#ef9a9a"       
    },
    {
        colorId: "#e57373"       
    },
    {
        colorId: "#ef5350"       
    },
    {
        colorId: "#f44336"       
    },
    {
        colorId: "#e53935"       
    },
    {
        colorId: "#d32f2f"       
    },
    {
        colorId: "#c62828"       
    },
    {
        colorId: "#b71c1c"       
    },
    {
        colorId: "#fce4ec"       
    },
    {
        colorId: "#f8bbd0"       
    },
    {
        colorId: "#f48fb1"       
    },
    {
        colorId: "#f06292"       
    },
    {
        colorId: "#ec407a"       
    },
    {
        colorId: "#e91e63"       
    },
    {
        colorId: "#d81b60"       
    },
    {
        colorId: "#c2185b"       
    },
    {
        colorId: "#ad1457"       
    },
    {
        colorId: "#880e4f"       
    },
    {
        colorId: "#f3e5f5"       
    },
    {
        colorId: "#e1bee7"       
    },
    {
        colorId: "#ce93d8"       
    },
    {
        colorId: "#ba68c8"       
    },
    {
        colorId: "#ab47bc"       
    },
    {
        colorId: "#9c27b0"       
    },
    {
        colorId: "#8e24aa"       
    },
    {
        colorId: "#7b1fa2"       
    },
    {
        colorId: "#6a1b9a"       
    },
    {
        colorId: "#4a148c"       
    },
    {
        colorId: "#ede7f6"       
    },
    {
        colorId: "#d1c4e9"       
    },
    {
        colorId: "#b39ddb"       
    },
    {
        colorId: "#9575cd"       
    },
    {
        colorId: "#7e57c2"       
    },
    {
        colorId: "#673ab7"       
    },
    {
        colorId: "#5e35b1"       
    },
    {
        colorId: "#512da8"       
    },
    {
        colorId: "#4527a0"       
    },
    {
        colorId: "#311b92"       
    },
    {
        colorId: "#e8eaf6"       
    },
    {
        colorId: "#c5cae9"       
    },
    {
        colorId: "#9fa8da"       
    },
    {
        colorId: "#7986cb"       
    },
    {
        colorId: "#5c6bc0"       
    },
    {
        colorId: "#3f51b5"       
    },
    {
        colorId: "#3949ab"       
    },
    {
        colorId: "#303f9f"       
    },
    {
        colorId: "#283593"       
    },
    {
        colorId: "#1a237e"       
    },
    {
        colorId: "#e3f2fd"       
    },
    {
        colorId: "#bbdefb"       
    },
    {
        colorId: "#90caf9"       
    },
    {
        colorId: "#64b5f6"       
    },
    {
        colorId: "#42a5f5"       
    },
    {
        colorId: "#2196f3"       
    },
    {
        colorId: "#1e88e5"       
    },
    {
        colorId: "#1976d2"       
    },
    {
        colorId: "#1565c0"       
    },
    {
        colorId: "#0d47a1"       
    },
    {
        colorId: "#e1f5fe"       
    },
    {
        colorId: "#b3e5fc"       
    },
    {
        colorId: "#81d4fa"       
    },
    {
        colorId: "#4fc3f7"       
    },
    {
        colorId: "#29b6f6"       
    },
    {
        colorId: "#03a9f4"       
    },
    {
        colorId: "#039be5"       
    },
    {
        colorId: "#0288d1"       
    },
    {
        colorId: "#0277bd"       
    },
    {
        colorId: "#01579b"       
    },
    {
        colorId: "#e0f7fa"       
    },
    {
        colorId: "#b2ebf2"       
    },
    {
        colorId: "#80deea"       
    },
    {
        colorId: "#4dd0e1"       
    },
    {
        colorId: "#26c6da"       
    },
    {
        colorId: "#00bcd4"       
    },
    {
        colorId: "#00acc1"       
    },
    {
        colorId: "#0097a7"       
    },
    {
        colorId: "#00838f"       
    },
    {
        colorId: "#006064"       
    },
    {
        colorId: "#e0f2f1"       
    },
    {
        colorId: "#b2dfdb"       
    },
    {
        colorId: "#80cbc4"       
    },
    {
        colorId: "#4db6ac"       
    },
    {
        colorId: "#26a69a"       
    },
    {
        colorId: "#009688"       
    },
    {
        colorId: "#00897b"       
    },
    {
        colorId: "#00796b"       
    },
    {
        colorId: "#00695c"       
    },
    {
        colorId: "#004d40"       
    },
    {
        colorId: "#e8f5e9"       
    },
    {
        colorId: "#c8e6c9"       
    },
    {
        colorId: "#a5d6a7"       
    },
    {
        colorId: "#81c784"       
    },
    {
        colorId: "#66bb6a"       
    },
    {
        colorId: "#4caf50"       
    },
    {
        colorId: "#43a047"       
    },
    {
        colorId: "#388e3c"       
    },
    {
        colorId: "#2e7d32"       
    },
    {
        colorId: "#1b5e20"       
    },
    {
        colorId: "#f1f8e9"       
    },
    {
        colorId: "#dcedc8"       
    },
    {
        colorId: "#c5e1a5"       
    },
    {
        colorId: "#aed581"       
    },
    {
        colorId: "#9ccc65"       
    },
    {
        colorId: "#8bc34a"       
    },
    {
        colorId: "#7cb342"       
    },
    {
        colorId: "#689f38"       
    },
    {
        colorId: "#558b2f"       
    },
    {
        colorId: "#33691e"       
    },
    {
        colorId: "#f9fbe7"       
    },
    {
        colorId: "#f0f4c3"       
    },
    {
        colorId: "#e6ee9c"       
    },
    {
        colorId: "#dce775"       
    },
    {
        colorId: "#d4e157"       
    },
    {
        colorId: "#cddc39"       
    },
    {
        colorId: "#c0ca33"       
    },
    {
        colorId: "#afb42b"       
    },
    {
        colorId: "#9e9d24"       
    },
    {
        colorId: "#827717"       
    },
    {
        colorId: "#fffde7"       
    },
    {
        colorId: "#fff9c4"       
    },
    {
        colorId: "#fff59d"       
    },
    {
        colorId: "#fff176"       
    },
    {
        colorId: "#ffee58"       
    },
    {
        colorId: "#ffeb3b"       
    },
    {
        colorId: "#fdd835"       
    },
    {
        colorId: "#fbc02d"       
    },
    {
        colorId: "#f9a825"       
    },
    {
        colorId: "#f57f17"       
    },
    {
        colorId: "#fff8e1"       
    },
    {
        colorId: "#ffecb3"       
    },
    {
        colorId: "#ffe082"       
    },
    {
        colorId: "#ffd54f"       
    },
    {
        colorId: "#ffca28"       
    },
    {
        colorId: "#ffc107"       
    },
    {
        colorId: "#ffb300"       
    },
    {
        colorId: "#ffa000"       
    },
    {
        colorId: "#ff8f00"       
    },
    {
        colorId: "#ff6f00"       
    },
    {
        colorId: "#fff3e0"       
    },
    {
        colorId: "#ffe0b2"       
    },
    {
        colorId: "#ffcc80"       
    },
    {
        colorId: "#ffb74d"       
    },
    {
        colorId: "#ffa726"       
    },
    {
        colorId: "#ff9800"       
    },
    {
        colorId: "#fb8c00"       
    },
    {
        colorId: "#f57c00"       
    },
    {
        colorId: "#ef6c00"       
    },
    {
        colorId: "#e65100"       
    },
    {
        colorId: "#fbe9e7"       
    },
    {
        colorId: "#ffccbc"       
    },
    {
        colorId: "#ffab91"       
    },
    {
        colorId: "#ff8a65"       
    },
    {
        colorId: "#ff7043"       
    },
    {
        colorId: "#ff5722"       
    },
    {
        colorId: "#f4511e"       
    },
    {
        colorId: "#e64a19"       
    },
    {
        colorId: "#d84315"       
    },
    {
        colorId: "#bf360c"       
    },
    {
        colorId: "#efebe9"       
    },
    {
        colorId: "#d7ccc8"       
    },
    {
        colorId: "#bcaaa4"       
    },
    {
        colorId: "#a1887f"       
    },
    {
        colorId: "#8d6e63"       
    },
    {
        colorId: "#795548"       
    },
    {
        colorId: "#6d4c41"       
    },
    {
        colorId: "#5d4037"       
    },
    {
        colorId: "#4e342e"       
    },
    {
        colorId: "#3e2723"       
    },
    {
        colorId: "#fafafa"       
    },
    {
        colorId: "#f5f5f5"       
    },
    {
        colorId: "#eeeeee"       
    },
    {
        colorId: "#e0e0e0"       
    },
    {
        colorId: "#bdbdbd"       
    },
    {
        colorId: "#9e9e9e"       
    },
    {
        colorId: "#757575"       
    },
    {
        colorId: "#616161"       
    },
    {
        colorId: "#424242"       
    },
    {
        colorId: "#212121"       
    },
    {
        colorId: "#eceff1"       
    },
    {
        colorId: "#cfd8dc"       
    },
    {
        colorId: "#b0bec5"       
    },
    {
        colorId: "#90a4ae"       
    },
    {
        colorId: "#78909c"       
    },
    {
        colorId: "#607d8b"       
    },
    {
        colorId: "#546e7a"       
    },
    {
        colorId: "#455a64"       
    },
    {
        colorId: "#37474f"       
    },
    {
        colorId: "#263238"       
    },
    {
        colorId: "#ffffff"       
    },
    {
        colorId: "#000000"       
    }
]