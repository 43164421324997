import { Component } from "react";
import { logout } from "../Auth/HandleJWT";
import { BilingAndPlans } from "../Popups/BilingAndPlans";
import { DeleteStore } from "../Popups/DeleteStore";
import { PrivacyPolicy } from "../Popups/PrivacyPolicy";
import { PromoteStore } from "../Popups/PromoteStore";
import { ReportBug } from "../Popups/ReportBug";
import { TermsOfUse } from "../Popups/TermsOfUse";
import styles from "./../CSSModules/Settings.module.css";

export class SettingsView extends Component {

    constructor() {
        super();
        this.state = {
            name: "Settings",
            isPopupOpen: false,
            popupName: ""
        };
    }

    openPopup(name) {
        this.setState({isPopupOpen: true, popupName: name});
    }

    closePopup() {
        this.setState({isPopupOpen: false, popupName: ""});
    }

    render() {

        var {isPopupOpen, popupName} = this.state;

        return (
        <>
            <div className={isPopupOpen ? styles.settingsHided : styles.settings}>
               <div className={styles.settingsButton} onClick={()=>{this.openPopup("PromoteStore")}}>Promote your 3D Store</div>
               <div className={styles.settingsButton} onClick={()=>{this.openPopup("BillingPlans")}}>Billing & Plans</div>
               <div className={styles.settingsButton} onClick={()=>{}}>Help Guide</div>
               <div className={styles.settingsButton} onClick={()=>{this.openPopup("ReportBug")}}>Report a Bug</div>
               <div className={styles.settingsButton} onClick={()=>{this.openPopup("TermsOfUse")}}>Terms & Conditions</div>
               <div className={styles.settingsButton} onClick={()=>{this.openPopup("PrivacyPolicy")}}>Privacy Policy</div>
               <div className={styles.settingsButton} onClick={()=>{logout()}}>Logout</div>
               <div className={styles.settingsButton+" "+styles.deleteButton} onClick={()=>{this.openPopup("DeleteStore")}}>Delete Store</div>
            </div>
            { popupName === "PromoteStore" && <PromoteStore onPopupClose={()=>{this.closePopup()}}></PromoteStore>}
            { popupName === "BillingPlans" && <BilingAndPlans onPopupClose={()=>{this.closePopup()}}></BilingAndPlans>}
            { popupName === "ReportBug" && <ReportBug onPopupClose={()=>{this.closePopup()}}></ReportBug>}
            { popupName === "TermsOfUse" && <TermsOfUse onPopupClose={()=>{this.closePopup()}}></TermsOfUse>}
            { popupName === "PrivacyPolicy" && <PrivacyPolicy onPopupClose={()=>{this.closePopup()}}></PrivacyPolicy>}
            { popupName === "DeleteStore" && <DeleteStore onPopupClose={()=>{this.closePopup()}}></DeleteStore>}
        </>
        )
    }
}