import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Component } from "react";
import styles from "./../CSSModules/AssetsView.module.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearOutlinedIcon from '@mui/icons-material/Clear';
import InputBase from '@mui/material/InputBase';
import React, { useState } from 'react';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center',
        marginLeft: "30px !important",
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    color: 'white',    
}));


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    display: 'flex',
    opacity: 0.6,
    paddingLeft: 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'white',
      },
    '&.Mui-expanded': {
        opacity: 1,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({    
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    padding: '0',
}));


function Icon({ src }) {
    const [position, setPosition] = useState({ x: 0, y: 0 });
  
    const handleMouseMove = e => {
      setPosition({ x: e.clientX-25, y: e.clientY-25 });
    };
  
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }} onMouseMove={handleMouseMove}>
        <img src={src} alt="cursorIcon" style={{ position: 'absolute', width: '50px', height: '50px', left: position.x, top: position.y }} />
      </div>
    );
  }

export class AssetsView extends Component {

    constructor() {
        super();
        this.state = {
            name: "Assets",
            hide: false,
            selection: null,           
            assets:null,
            loading: true,
            expanded: "rails",
            searchValue: "",
            selections: [],
            asset: null,
            filteredAssets: null,
            activeDrags: 0,
            isTutorialDragTooltipOpen: false,
            isTutorialFirstDragTooltipOpen: true,
            x:0,
            y:0,
        };
        
        window.addEventListener('mouseup', this.clickUp);
        window.onUnityDragEnd = this.onUnityDragEnd;
    }

    clickUp = (e) => {        
        if (window.unityMounted) {
            window.unity.SendMessage('bloodbridge', "itemdragend", "");

            
            if (this.state.asset) {
                var tutorialData = this.props.tutorialData;

                if(this.state.asset && this.state.asset.key === "Rail" && tutorialData && tutorialData.isTutorialOpen) {
                    this.setState({ asset: null, isTutorialFirstDragTooltipOpen: false });
                    setTimeout(()=> {
                        this.setState({ hide: false, isTutorialDragTooltipOpen: false });
                        this.props.onDropTutorialFinished();
                    }, 5000);
                }
                else {
                    this.setState({ asset: null, hide: false });
                }
            }
        }
    }

    componentDidMount() {
        this.getAssets();
    }

    componentWillUnmount() {        
        window.removeEventListener('mouseup', this.clickUp);
    }

    handleChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: newExpanded ? panel : false });
    }

    focusInput(e){
        window.unity.SendMessage('bloodbridge', "disablekeycapture", "");    }

    blurInput(e){
        window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");
    }

    setValue(value) {
        if(value.length > 0) {
            var filtered = this.state.assets.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));
            this.setState({filteredAssets: filtered});        
        }
        else {
            this.setState({filteredAssets: this.state.assets});
        }
        this.setState({ searchValue: value });
    }

    renderAssetsData() {
        var { expanded, hide, isTutorialFirstDragTooltipOpen} = this.state;
        var assets = this.state.filteredAssets;

        var tutorialData = this.props.tutorialData;

        return (<div>
            <Accordion expanded={expanded === 'rails'} onChange={this.handleChange('rails')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="railsa-content"
                id="railsa-header">
                <div className={styles.accordionHeader}>
                    <img className={styles.accordionImg } src="./images/icons/Rail.png" />
                    <div>Clothing Rails</div>
                </div>
            </AccordionSummary>
                <BootstrapTooltip
                    key={"assetsView"}
                    open={!hide && tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 3 && isTutorialFirstDragTooltipOpen}
                    placement="right"
                    title={"Click the fixture and drag it to add to your store!"}>
                    <AccordionDetails>
                        {
                            assets != null &&
                            <ImageList sx={{ width: 270, maxHeight: 'calc(100vh - 390px)', height: 'auto' }} cols={2} rowHeight={130}>
                                {assets.map((item, index) => item.typeId === 1 ? (
                                    (index === 0 && !hide && tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 3 && isTutorialFirstDragTooltipOpen)
                                        ?
                                        <ImageListItem key={item.id}>
                                            <div onDragStart={() => this.onStart(item)} className={styles.imgCursorContainer}>
                                                <img className={styles.imgItem}
                                                    src={`${item.thumbnailUrl}?w=100&h=100`}
                                                    alt={item.title}
                                                    name={item.key}
                                                    loading="lazy" />
                                                <img className={styles.imgItemCursorIcon}
                                                        src="./images/icons/mouse_ic.png"
                                                        alt="cursorIcon"
                                                        name="cursorIcon" />
                                            </div>
                                        </ImageListItem>
                                        :
                                        <ImageListItem key={item.id} >
                                            <div onDragStart={() => this.onStart(item)} className={styles.imgContainer}>
                                                <img className={styles.imgItem}
                                                    src={`${item.thumbnailUrl}?w=100&h=100`}
                                                    alt={item.title}
                                                    name={item.key}
                                                    loading="lazy" />
                                            </div>
                                        </ImageListItem>
                                ) : '')}
                            </ImageList>
                        }
                    </AccordionDetails>
                </BootstrapTooltip>
        </Accordion>
        <Accordion expanded={expanded === 'shelfs'} onChange={this.handleChange('shelfs')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="shelfsa-content"
                id="shelfsa-header">
                <div className={styles.accordionHeader}>
                    <img className={styles.accordionImg } src="./images/icons/Shelves.png" />
                    <div>Shelving Units</div>
                </div>                        
            </AccordionSummary>
            <AccordionDetails>
            {
               assets != null && <ImageList sx={{ width: 270, maxHeight: 'calc(100vh - 390px)', height: 'auto' }} cols={2} rowHeight={130}>
                    {assets.map((item) => item.typeId === 2 ? (
                        <ImageListItem key={item.id}>
                            <div onDragStart={()=> this.onStart(item)} className={styles.imgContainer}>
                                    <img className={styles.imgItem}
                                        src={`${item.thumbnailUrl}?w=100&h=100&fit=crop&auto=format`}
                                        alt={item.title}
                                        loading="lazy"/>
                            </div>
                        </ImageListItem>
                    ) : '')}
                </ImageList>
                }
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'additional'} onChange={this.handleChange('additional')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="additionala-content"
                id="additionala-header">
                <div className={styles.accordionHeader}>
                    <img className={styles.accordionImg } src="./images/icons/AdditionalAssets.png" />                    
                    <div>Additional Assets</div>
                </div>                        
            </AccordionSummary>
            <AccordionDetails>
               {
               assets != null && <ImageList sx={{ width: 270, height: 'calc(100vh - 390px)', minHeight: 340 }} cols={2} rowHeight={130}>
                    {assets.map((item) => item.typeId === 3 ? (
                        <ImageListItem key={item.id}>
                            <div onDragStart={()=> this.onStart(item)} className={styles.imgContainer}>
                                    <img className={styles.imgItem}
                                        src={`${item.thumbnailUrl}?w=100&h=100&fit=crop&auto=format`}
                                        alt={item.title}
                                        loading="lazy"/>
                            </div>
                        </ImageListItem>
                    ) : '')}
                </ImageList>
                }
            </AccordionDetails>
        </Accordion>
        </div>
        );
    }

    handleMouseMove = e => {
        var {x, y} = this.state;
        if(x !== e.clientX || y !== e.clientY) {
            this.setState({ x: e.clientX, y: e.clientY });
        }
    };

    render() {
        var { searchValue, hide, asset, isTutorialDragTooltipOpen, x, y } = this.state;

        var tutorialData = this.props.tutorialData;

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAssetsData();

        return (<>
            <div className={hide ? styles.assetsHide : styles.assets}>
               <h4 className={styles.assetsHeader}>Add fixtures</h4>
                <div className={styles.searchContainer}>
                    <SearchIcon sx={{ color: 'lightgray', mx: '5px'}} />
                    <InputBase                       
                        placeholder="Search"
                        sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.searchTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        size="small"
                        onFocus={(e) => this.focusInput(e)}
                        onBlur={(e) => this.blurInput(e)}
                        onChange={(e) => this.setValue(e.target.value)}
                        value={searchValue} />

                    {searchValue.length > 0 
                        ? <IconButton onClick={()=>this.setValue("")}>
                            <ClearOutlinedIcon sx={{ color: 'lightgray' }}/>
                        </IconButton> 
                        : ''}                   
                </div>
                {contents}
            </div>
            {
                isTutorialDragTooltipOpen &&
                <BootstrapTooltip
                    key={"assetView"}
                    open={isTutorialDragTooltipOpen}
                    followCursor
                    placement="right"
                    title={asset ? "Drop the furniture into the store." : "Great! You can add any fixtures, products, and wall art in this way."}>
                    <div className={styles.folowCursorContainer}>
                       <Icon src="./images/icons/mouse_ic.png"/>
                    </div>
                </BootstrapTooltip>
            }
        </>)
    }

    async getAssets() {
        const response = await fetch('api/account/getAssets');
        const data = await response.json();
        this.setState({ assets: data, filteredAssets: data, loading: false });
    }

    /*DnD */
    onStart(asset) {
        if (this.state.asset != null) {
            return;
        }

        var isTutorialDragTooltipOpen = false;
        var tutorialData = this.props.tutorialData;

        if(asset && asset.key === "Rail" && tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 3) {
            isTutorialDragTooltipOpen = true;
        }
        
        this.setState({hide: true, asset: asset, isTutorialDragTooltipOpen: isTutorialDragTooltipOpen});
        console.log(asset.key);

        if (window.unityMounted) {
            window.unity.SendMessage('bloodbridge', "enablekeycapture", "");
            window.unity.SendMessage('bloodbridge', "assetdragstart", asset.key);
        }       

        document.getElementById("unity-canvas").focus();
        document.getElementById("unity-canvas").click();
    };

    onUnityDragEnd(){
        console.log("Assets -> OnUnityStopDrag");        
    }
}