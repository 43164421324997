
import { Component } from "react";
import { CustomDialog, CustomDialogTitle } from "./CustomDialog"
import * as React from 'react';
import styles from "./../CSSModules/Popups/BilingAndPlan.module.css";
import { Checkbox, CircularProgress, DialogActions, DialogContent, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class BilingAndPlans extends Component {

    constructor() {
        super();
        this.state = {
            name: "BilingAndPlans",
            open: true,
            startStoreSize: 1,
            storeSize: 1,
            aiPlan: 0,
            startAIPlan: 0,
            isProceed: false,
            loading: false,
        };
    }


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.onPopupClose();
    };

    handleUpgradeClose = () => {
        this.setState({ isProceed: false });
    }

    handleChangeSize(sizeId) {
        this.setState({ storeSize: sizeId });
    }

    handleChangeAIPlan(aiPlanId) {
        this.setState({ aiPlan: aiPlanId });
    }

    changeBillingPlan() {
        this.setState({ isProceed: true });
    }

    proceedBillingPlan() {
        this.setState({ isProceed: false });
    }

    renderLoader() {
        return <div className='loader'>
            <CircularProgress color="secondary" />
        </div>
    }
    
    renderUpgradeDialog() {
        var { isProceed, loading, startAIPlan, aiPlan, startStoreSize, storeSize } = this.state;


        return <CustomDialog className={styles.billingStoreDialog}
            maxWidth={"600px"}
            open={isProceed}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleUpgradeClose}
            aria-describedby="alert-dialog-slide-description">
            {loading && this.renderLoader()}
            <CustomDialogTitle>{"Let's Upgrade!"}</CustomDialogTitle>
            <DialogContent>
                <div className={styles.upgradePlanDescription}>
                   {startStoreSize !== storeSize && startStoreSize === 1 && <div>You're about to upgrade your 3D store from size 'small' to 'medium'.</div>}
                   {startAIPlan !== aiPlan && aiPlan === 1 && <div>You're about to upgrade your 3D store from AI Virtual Assistant 'basic' to 'advanced'.</div>}
                   {startAIPlan !== aiPlan && aiPlan === 2 && <div>You're about to upgrade your 3D store from AI Virtual Assistant 'basic' to 'evolved'.</div>}
                 
                   <div>This plan will cost an extra &#163;XXX.XX per month.</div>
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: "25px 0 35px 0", backgroundColor: "#1a102c", color: "white", display: "flex", justifyContent: "center" }}>
                <div className={styles.cancelBillingPlanButton} onClick={() => { this.handleUpgradeClose() }}>Cancel</div>
                <div className={styles.proceedBillingPlanButton} onClick={() => { this.proceedBillingPlan() }}>Proceed</div>
            </DialogActions>
        </CustomDialog>
    }

    render() {
        var { open, storeSize, aiPlan, isProceed } = this.state;

        return (<>
            <CustomDialog className={isProceed ? styles.billingStoreDialogHide : styles.billingStoreDialog}
                maxWidth={"710px"}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    <div className={styles.billingContainer}>
                        <h5 className={styles.billingLabel}>
                            Billing & Plans
                        </h5>
                        <div className={styles.billingInputContainer}>
                            <div className={styles.billingPaymentImgContainer}>
                                <img className={styles.billingPaymentImg}
                                    src="./images/logo/visa-logo.png"
                                    alt="paymentLogo"
                                    loading="lazy" />
                            </div>

                            <div className={styles.billingStorePaymentDescription}>
                                <div>Visa **** 0123</div>
                                <div>Expires January 2024</div>
                            </div>

                        </div>
                        <div className={styles.changePaymentButton} onClick={() => { }}>Change</div>
                        <h5 className={styles.billingLabel}>
                            Store Plan
                        </h5>
                        <div className={styles.billingStorePlanContainer}>
                            <div className={styles.billingStoreSizeContainer + ' ' + (storeSize === 1 ? styles.billingStoreSelectedSize : '')} onClick={() => { this.handleChangeSize(1) }}>
                                <Checkbox name="storeSize"
                                    checked={storeSize === 1}
                                    className={styles.billingStoreSizeCheckbox}
                                    size="small"
                                    sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }} />

                                <div className={styles.billingStoreSizeImgContainer}>
                                    <img className={styles.billingStoreSizeImg}
                                        src="./images/icons/Store_Size_Small.png"
                                        alt="StoreSizeSmall"
                                        loading="lazy" />
                                </div>

                                <div className={styles.billingStoreSizeDescription}>
                                    <div className={styles.billingStoreSizeDescriptionTitle}>Small Store</div>
                                    <div>Ideal for up to 50 products</div>
                                </div>

                            </div>
                            <div className={styles.billingStoreSizeContainer + ' ' + (storeSize === 0 ? styles.billingStoreSelectedSize : '')} onClick={() => { this.handleChangeSize(0) }}>
                                <Checkbox name="storeSize"
                                    checked={storeSize === 0}
                                    className={styles.billingStoreSizeCheckbox}
                                    size="small"
                                    sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }} />

                                <div className={styles.billingStoreSizeImgContainer}>
                                    <img className={styles.billingStoreSizeImg}
                                        src="./images/icons/Store_Size_Big.png"
                                        alt="StoreSizeBig"
                                        loading="lazy" />
                                </div>

                                <div className={styles.billingStoreSizeDescription}>
                                    <div className={styles.billingStoreSizeDescriptionTitle}>Medium Store</div>
                                    <div>Ideal for up to 100 products</div>
                                </div>

                            </div>
                        </div>
                        <h5 className={styles.billingLabel}>
                            AI Virtual Assistant Plan
                        </h5>
                        <div className={styles.billingStoreAIPlanContainer}>
                            <div className={styles.billingStoreAIPlanBox + ' ' + (aiPlan === 0 ? styles.billingStoreSelectedAIPlan : '')} onClick={() => { this.handleChangeAIPlan(0) }}>
                                <Checkbox name="aiPlan"
                                    checked={aiPlan === 0}
                                    className={styles.billingStoreAIPlanCheckbox}
                                    size="small"
                                    sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }} />

                                <div className={styles.billingStoreAIPlanDescription}>
                                    <div className={styles.billingStoreAIPlanDescriptionTitle}>Basic Assistant</div>
                                    <div>Help with FAQs or simple questions.</div>
                                </div>

                            </div>
                            <div className={styles.billingStoreAIPlanBox + ' ' + (aiPlan === 1 ? styles.billingStoreSelectedAIPlan : '')} onClick={() => { this.handleChangeAIPlan(1) }}>
                                <Checkbox name="aiPlan"
                                    checked={aiPlan === 1}
                                    className={styles.billingStoreAIPlanCheckbox}
                                    size="small"
                                    sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }} />

                                <div className={styles.billingStoreAIPlanDescription}>
                                    <div className={styles.billingStoreAIPlanDescriptionTitle}>Advanced Assistant</div>
                                    <div>Includes basic features and can relay product information to buyers.</div>
                                </div>

                            </div>
                            <div className={styles.billingStoreAIPlanBox + ' ' + (aiPlan === 2 ? styles.billingStoreSelectedAIPlan : '')} onClick={() => { this.handleChangeAIPlan(2) }}>
                                <Checkbox name="aiPlan"
                                    checked={aiPlan === 2}
                                    className={styles.billingStoreAIPlanCheckbox}
                                    size="small"
                                    sx={{
                                        color: "#2f125e",
                                        '&.Mui-checked': {
                                            color: "#2f125e",
                                        },
                                    }} />

                                <div className={styles.billingStoreAIPlanDescription}>
                                    <div className={styles.billingStoreAIPlanDescriptionTitle}>Evolved Assistant</div>
                                    <div>Includes advanced features and is able to hold conversations.</div>
                                </div>
                            </div>

                        </div>
                    </div>

                </DialogContent>
                <DialogActions sx={{ padding: "0px 0 35px 0", backgroundColor: "#1a102c", color: "white", display: "flex", justifyContent: "center" }}>
                    <div className={styles.cancelBillingPlanButton} onClick={() => { this.handleClose() }}>Cancel</div>
                    <div className={styles.proceedBillingPlanButton} onClick={() => { this.changeBillingPlan() }}>Proceed</div>
                </DialogActions>
            </CustomDialog>
            {isProceed && this.renderUpgradeDialog()}
        </>)
    }
}