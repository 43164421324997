import { CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import { Sidenav } from './Sidenav';
import { ChatBot } from './ChatBot/ChatBot';
import { Feedback } from './Popups/FeedbackDialog';
import { TutorialStepper } from './TutorialStepper';
import { RequestVideoMeeting } from './Popups/RequestVideoMeetingDialog';

export class AdminHome extends Component {
  static displayName = AdminHome.name;

  constructor() {
    super();
    this.state = {
      name: "AdminHome",
      unityMounted: false,
      sceneStateKey: "sceneState",
      loading: true,
      sceneStateId: 0,
      sceneState: null,
      isStorePublished: false,
      storeName: "",
      storeId: 0,
    };

    this.onUnityMounted = this.onUnityMounted.bind(this);
    this.onStoreInitialized = this.onStoreInitialized.bind(this);

    window.onSaveSceneState = this.saveUnitySceneData;
    window.saveSceneFunc = this.saveScene.bind(this); 

    this.sidenavRef = React.createRef();
    this.tutorialRef = React.createRef();
  }


  componentDidMount() {    
    this.getSceneState();
    window.onUnityMount = this.onUnityMounted;
    window.onStoreInitialized = this.onStoreInitialized;
  }

  async getSceneState() {
    const response = await fetch('api/Account/GetSceneState');
    const data = await response.json();

    if (response.status != 200) {
      console.error(data);
    }
    else {
      this.setState({ sceneState: data.sceneState, sceneStateId: data.sceneStateId });
      if(window.unityMounted) {
        this.setSceneState();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('onUnityMount', this.onUnityMounted);
    window.removeEventListener('onStoreInitialized', this.onStoreInitialized);
    window.removeEventListener('onSaveSceneState', this.saveUnitySceneData);    
  }

  onStoreInitialized() {
    console.log("Unity3D initialized");
    setTimeout(() => this.setState({ loading: false }), 1500);
    //setTimeout(() => this.childChatBotRef.current.playIntro(), 6000);   
  }

  onUnityMounted() {
    console.log("Unity3D loaded");
    window.unityMounted = true;

    setTimeout(() => this.sidenavRef.current.updateUserDataOnUinity(), 250);

    this.setSceneState();

    setInterval(() => {
      console.log("Save unity scene every 45sec");
      window.unity.SendMessage('bloodbridge', "savescenestate", "");
    }, 45000);
  }

  setSceneState() {
    if (this.state.sceneState) {
      console.log("Init Scene ***********************");
      setTimeout(() => {        
        window.unity.SendMessage('bloodbridge', "initscenestate", this.state.sceneState);
      }, 500);
    }
  }

  saveUnitySceneData(sceneStateJson) {   
    if (sceneStateJson) {
      window.saveSceneFunc(sceneStateJson);      
    }
  }

  async saveScene(sceneStateJson) {
    //SaveScene
    const response = await fetch(`api/Account/SaveScene/${this.state.sceneStateId}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(sceneStateJson)
    });

    const data = await response.json();

    if (response.status != 200) {
      console.error('HTTP response code status->' + response.status + ' message->' + data);
    }
    else {
      if (data && data !== this.state.sceneStateId) {
        this.setState({ sceneStateId: data });
      }
    }
  }

  onTutorialStepChange(tutorialData) {
    this.sidenavRef.current.onTutorialStepChange(tutorialData);
    console.log(tutorialData);
  }

  setPublishedStore(storeId) {
    if(!this.state.loading) {
      this.tutorialRef.current.storePublished(); 
    }
    this.setState({storeId: storeId, isStorePublished: true});
  }

  render() {

    var { loading, isStorePublished, storeId } = this.state;

    let contents = loading
      ? <div className='loader'>
        <CircularProgress color="secondary" />
      </div>
      : "";

    return <>      
      {contents}
      <Sidenav key="sidenav" ref={this.sidenavRef} onDropTutorialFinished={() => this.tutorialRef.current.onDropTutorialFinished()} storePublished={(storeId) => this.setPublishedStore(storeId)}/>
      <ChatBot key="chatbot" />
      <Feedback key="feedback" />
      {!loading && <TutorialStepper key="stepper" disabled={false} isStorePublished={isStorePublished} ref={this.tutorialRef} onTutorialDataChange={(tutorialData)=>this.onTutorialStepChange(tutorialData)}/>}
      {!loading && isStorePublished && storeId > 0 && <RequestVideoMeeting key="request" storeId={storeId}/>}
    </>;
  }
}
