import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export const CustomDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        padding: "0px 35px",
        backgroundColor: "#1a102c",
        color: "white",
        maxWidth: "710px",

    },
}));

export function CustomDialogTitle(props) {
    const { children, ...other } = props;

    return (
        <DialogTitle sx={{ padding: "25px 0px", fontFamily: 'Cosmata Bold', textAlign: "center", backgroundColor: "#1a102c", color: "white" }} {...other}>
            {children}
        </DialogTitle>
    );
}
