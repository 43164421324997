export const floorImageData = [    
    {
        id: 0,
        icon: "./images/floor/Wood.jpg"
    },
    {
        id: 1,
        icon: "./images/floor/Carpet.png"
    },
    {
        id: 2,
        icon: "./images/floor/Wood_Thmb.jpg"
    },
    {
        id: 3,
        icon: "./images/floor/Fabric.jpg"
    }
]