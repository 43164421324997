
import { Component } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import styles from "./../CSSModules/Popups/RequestVideoMeetingDialog.module.scss";
import { DialogContent, Slide } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AgoraRTM from 'agora-rtm-sdk';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const RequestVideoMeetingDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {        
        backgroundColor: "#F4F6FC",
        color: "black",       
        maxHeight: "90%",
        borderRadius: "16px",
        overflowX: "hidden",
    }
}));

export function RequestVideoMeetingDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ padding: "25px 0px", fontFamily: 'Cosmata Bold', textAlign: "center", backgroundColor: "#F4F6FC", color: "black" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export class RequestVideoMeeting extends Component {

    constructor() {
        super();
        this.state = {
            name: "RequestVideoMeeting",
            open: false,
            storeId: 0,
            requestLinks: [],
            uid: "",
            token: ""
        };
       
        this.channel = null;
    }

    componentDidMount() {
        this.setState({storeId: this.props.storeId});

        if(this.props.storeId > 0) {            
            this.getAgoraUserData(this.props.storeId);
        }
    }

    componentWillUnmount() {       
        if (this.channel) {            
            this.channel.leave();
        }
    }

    async getAgoraUserData(storeId) {
        const response = await fetch(`api/auth/GetAgoraUserData/${storeId}`);
        const data = await response.json();

        if (response.status != 200) {
            console.error('HTTP response code = ' + response.status + ' message=' + data?.message);
            return;
        }
        else {            
            this.setState({ uid: data.uid, token: data.token });
            this.initSignaling(storeId, data.uid, data.token);
        }
    }

    async initSignaling(id, uid, token) {
        const appID = "6bce14764dc04670820efe44482703ed";
        const client = AgoraRTM.createInstance(appID);

        let options = {
            uid: uid,
            token: token
        };

        let that = this;

        // Client Event listeners
        // Display messages from peer
        client.on('MessageFromPeer', function (message, peerId) {
            //console.log("Message from: " + peerId + " Message: " + message);
        });

        // Display connection state changes
        client.on('ConnectionStateChanged', function (state, reason) {
            //console.log("State changed To: " + state + " Reason: " + reason);
        });

        let channelName = "roomId-"+id;
        this.channel = client.createChannel(channelName);

        this.channel.on('ChannelMessage', function (message, memberId) {
            console.log(message);
            var data = JSON.parse(message.text);

            if (!data.isOwner && !data.link) {
                //send message                
                that.channel.sendMessage({ text: JSON.stringify({ isOwner: true, link: "", isJoin: true }) });
            }
            else if(!data.isOwner && data.link) {                
                var links = that.state.requestLinks;
                links.push(data.link);
                that.setState({requestLinks: links}, () => that.openDialog());
            }
        });

        // Display channel member stats
        this.channel.on('MemberJoined', function (memberId) {
            console.log(memberId + " joined the channel");
        })

        // Display channel member stats
        this.channel.on('MemberLeft', function (memberId) {
            console.log(memberId + " left the channel");
        });

        await client.login(options);

        await this.channel.join().then (() => {
            console.log("You have successfully joined channel " + this.channel.channelId);
        });

        //send message
        let msgObj =  { isOwner: true, link: "", isJoin: true };
        let msg = JSON.stringify(msgObj);

        await this.channel.sendMessage({ text: msg });
    }

    openDialog = () => {
        var { open, requestLinks} = this.state;        

        if(!open && requestLinks.length> 0) {
            this.setState({ open: true });
        }        
    };

    closeDialog = () => {
        var { requestLinks} = this.state;
        requestLinks.shift();

        this.setState({ open: false, requestLinks: requestLinks }, ()=> {
            if(requestLinks.length > 0) {
                setTimeout(()=>this.openDialog(), 1000);
            }
        });
    };

    join() {
        var { requestLinks} = this.state;
        var linkToJoin = requestLinks.shift();

        window.open(linkToJoin, '_blank');

        this.closeDialog();
    }

    renderRequestVideoChat() {
        var { open } = this.state;

        return <RequestVideoMeetingDialog className="requestVideoChatDialog"
            sx={{ '& .MuiPaper-root': { bgcolor: "#F4F6FC", borderRadius: "16px", maxWidth: "450px", padding: "0", } }}
            maxWidth={"450px"}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.closeDialog}
            aria-describedby="alert-dialog-slide-description">
            <RequestVideoMeetingDialogTitle onClose={this.closeDialog}></RequestVideoMeetingDialogTitle>
            <DialogContent>
                <h5 className={styles.requestVideoChatTitle}>Video Meeting requested</h5>

                <div className={styles.requestVideoChatDescription}>
                    Your customer has requested a meeting with you.
                </div>

                <div className={styles.actionRequestContainer}>
                    <div className={styles.actionRequestCancelButton} onClick={() => this.closeDialog()}>Decline Meeting</div>
                    <div className={styles.actionRequestJoinButton} onClick={() => this.join()}>Join Video Meeting</div>
                </div>
            </DialogContent>
        </RequestVideoMeetingDialog>;
    }

    render() {
        var {  } = this.state;
        return <> {this.renderRequestVideoChat()} </>
    }   
}