import React, { Component } from 'react';
import { StoreLogoStep } from './SetupStoreSteps/StoreLogoStep';
import { StoreNameStep } from './SetupStoreSteps/StoreNameStep';
import { StoreSizeStep } from './SetupStoreSteps/StoreSizeStep';
import { StoreThemeStep } from './SetupStoreSteps/StoreThemeStep';
import styles from "./CSSModules/SetupStore.module.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { saveStoreExist } from './Auth/HandleJWT';
import { CircularProgress } from '@mui/material';
import { TutorialStepper } from './TutorialStepper';


export class SetupStore extends Component {
    static displayName = "SetupStore"; 

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            loading: false,
            storeName: "",
            storeSize: 1,
            storeTheme: 0,
            storeLogoUrl: "",
            storeLogoFile: null
        };
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })

        if (name === "storeLogoUrl") {
            this.setState({ storeLogoFile: null });
        }
    }

    handleChangeSize = value => {
        this.setState({ storeSize: value });
    }

    handleChangeTheme = value => {
        this.setState({ storeTheme: value });
    }

    handleChangeLogoFile = file => {
        this.setState({ storeLogoFile: file, storeLogoUrl: "" });
    }

    handleSubmit = event => {
        event.preventDefault()
        //const { storeName, storeLogoUrl, storeSize, storeTheme } = this.state;
        //alert(`Store name -> ${storeName}, Store size -> ${storeSize}, Store theme -> ${storeTheme}, Logo -> ${storeLogoUrl}`)
        this.saveStoreData();
    }

    async saveStoreData() {

        this.setState({ loading: true });

        const form = new FormData();
        form.append("storeLogoFile", this.state.storeLogoFile);
        form.append("storeName", this.state.storeName);
        form.append("storeSize", this.state.storeSize);
        form.append("storeTheme", this.state.storeTheme);
        form.append("storeLogoUrl", this.state.storeLogoUrl);

        const response = await fetch('api/account/setupStore', {
            method: 'POST',           
            body: form
        });

        const data = await response.json();

        if (response.status != 200) {
            console.error('HTTP response code = '+response.status +' message='+ data.message);
            return;
        }

        this.setState({ loading: false });
        saveStoreExist();
        window.location.href = '/';
    }

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 1 ? 2 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <div className={styles.setupStoreBackButton} onClick={this._prev}><ArrowBackIosIcon fontSize="small" /> Back</div>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 2) {
            return (
                <div className={styles.setupStoreNextButton} onClick={this._next}>Skip <ArrowForwardIosIcon fontSize="small" /></div>
            )
        }
        return null;
    }

    sendButton() {
        let currentStep = this.state.currentStep;
        if (currentStep === 2) {
            return (

                <div className={styles.setupStoreNextButton} onClick={this.handleSubmit}>Skip <ArrowForwardIosIcon fontSize="small" /></div>
            )
        }
        return null;
    }

    onTutorialStepChange(tutorialData) {
        
    }

    render() {
        var { loading } = this.state;

        return (<>
            {
            loading ?
                <div className='loader'>
                    <CircularProgress color="secondary" />
                </div>
                : ""
            }
            <TutorialStepper disabled={true} isStorePublished={false} onTutorialDataChange={(tutorialData)=>this.onTutorialStepChange(tutorialData)}/>
            
            <div className={styles.setupStoreContainer}>
                <div className={styles.setupStoreCompanyName}>
                    <span className={styles.setupStoreCompanyNameDot}></span>
                    <h4 className={styles.setupStoreCompanyName}>Spaces by BrandLab360</h4>
                </div>
                <div className={styles.setupStoreFormContainer}>
                    <form className={styles.setupStoreForm}>
                        <StoreNameStep
                            styles={styles}
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            storeName={this.state.storeName}
                            handleNext={this._next}
                        />                       
                        <StoreLogoStep
                            styles={styles}
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            storeLogoUrl={this.state.storeLogoUrl}
                            storeLogoFile={this.state.storeLogoFile}
                            handleChangeLogoFile={this.handleChangeLogoFile}
                            handleNext={this.handleSubmit}
                        />
                        <div className={styles.setupStoreFooterButtons}>
                            {this.previousButton()}
                            {this.nextButton()}
                            {this.sendButton()}
                        </div>

                    </form>
                </div>
            </div>
        </>);

        /*
        <StoreSizeStep
            styles={styles}
            currentStep={this.state.currentStep}
            handleChangeSize={this.handleChangeSize}
            storeSize={this.state.storeSize}
            handleNext={this._next}
        />
        <StoreThemeStep
            styles={styles}
            currentStep={this.state.currentStep}
            handleChangeTheme={this.handleChangeTheme}
            storeTheme={this.state.storeTheme}
            handleNext={this._next}
        />
        */
    }
}
