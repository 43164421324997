import { Alert, Checkbox, CircularProgress, InputBase } from '@mui/material';
import React, { Component } from 'react';
import { RegisterUserModel } from '../../models/registerUser.model';
import styles from "./../CSSModules/Register.module.css";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { clearLocalStorage, saveToken } from './HandleJWT';

export class RegisterView extends Component {
  static displayName = RegisterView.name;

  constructor() {
    super();

    this.state = {
      name: "Register",
      loading: false,
      user: new RegisterUserModel("", "", "", "", "", "", false, false),      
      showPassword: false,
      showCPassword: false,
      openSnackBar: false,
      snackBarType: "success",
      snackBarMsg: ""
    };

    clearLocalStorage();
  } 

  handleClickShowPassword = () => this.setState({showPassword: !this.state.showPassword});
  handleClickShowCPassword = () => this.setState({showCPassword: !this.state.showCPassword});

  handleMouseDownPassword = (event) => event.preventDefault();

  handleChange = (value, arg) => this.updateUserField(arg, value);

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({openSnackBar: false});
  };

  setSnackBarMessage(isOpen, isError, message) {
    if(isError) 
      this.setState({snackBarType: "error", snackBarMsg: message, openSnackBar: isOpen });
    else 
      this.setState({snackBarType: "success", snackBarMsg: message, openSnackBar: isOpen});
  }

  updateUserField(field, value) {
    let _user = this.state.user;

    if (this.state.user != null)
      switch (field) {
        case "firstname": _user.firstName = value; break;
        case "lastname": _user.lastName = value; break;
        case "email": _user.email = value; break;
        case "password": _user.password = value; break;
        case "pnumber": _user.phoneNumber = value; break;
        case "cpassword": _user.confirmPassword = value; break;       
      }

    this.setState({ user: _user });
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  isFormValid = () => {
    return !this.validateField("password") && !this.validateField("cpassword") && !this.validateField("email");
  }

  validateField(field) {
    let _user = this.state.user;

    switch (field) {
      case "email":
        if (_user.email == null)
          return "Email invalid";
        let isValid = this.validateEmail(_user.email);
        if (isValid)
          return null;
        else
          return "Email invalid"
        break;
      case "password":
        if (_user.password != null && _user.password.length > 8)
          return null;
        else
          return "Length needs to be higher than 8";
        break;
      case "cpassword":
        if (_user.confirmPassword != null && _user.confirmPassword.length > 8 && _user.password === _user.confirmPassword)
          return null;
        else if (_user.confirmPassword != null && _user.confirmPassword.length <= 8)
          return "Length needs to be higher than 8";
        else if (_user.confirmPassword != null && _user.password !== _user.confirmPassword)
          return "Passwords do NOT match.!"; 
        break;
    }
    return null;
  }
  
  render() {
    var {
      user, 
      loading, 
      showPassword, 
      showCPassword, 
      openSnackBar,
      snackBarMsg,
      snackBarType
    } = this.state;

    let contents = 
      <div className={styles.registerContainer}>
        <div className={styles.formContainer}>
          <div className={styles.formCompanyName}>
            <span className={styles.formCompanyNameDot}></span>
            <h4 className={styles.companyName}>SPAC3S.IO</h4>
          </div>
          <div className={styles.form}>
            <h2 className={styles.formTitle}>Register</h2>
            <div className={styles.formText}>Welcome to Spaces by BrandLab360. Please enter your details.</div>
            <div className={styles.formInputRow}>
              <div className={styles.formRowItem}>
                <label htmlFor="fname">First Name</label>
                <InputBase                        
                        id="fname" 
                        name="fname"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.formTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        size="small"                        
                        onChange={(e) => this.handleChange(e.target.value, "firstname")}                        
                        value={user.firstName} />
              </div>
              <div className={styles.formRowItem}>
                <label htmlFor="lname">Last Name</label>
                <InputBase                        
                        id="lname" 
                        name="lname"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.formTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        size="small"                        
                        onChange={(e) => this.handleChange(e.target.value, "lastname")}                        
                        value={user.lastName} />
              </div>
            </div>
            <div className={styles.formInputRow}>
              <div className={styles.formRowItem}>
                <label htmlFor="pnumber">Phone Number</label>
                <InputBase                        
                        id="pnumber" 
                        name="pnumber"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.formTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        size="small"                        
                        onChange={(e) => this.handleChange(e.target.value, "pnumber")}                        
                        value={user.phoneNumber} />                
              </div>
              <div className={styles.formRowItem}>
                <label htmlFor="email">Email</label>
                <InputBase                        
                        id="email" 
                        name="email"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.formTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        size="small"                        
                        onChange={(e) => this.handleChange(e.target.value, "email")}                        
                        value={user.email} />                
                {user.email && <p className={styles.errorMsg}>{this.validateField("email")}</p>}
              </div>
            </div>
            <div className={styles.formInputRow + " m-b-20" }>
              <div className={styles.formRowItem}>
                <label htmlFor="password">Password</label>
                <InputBase                        
                        id="password" 
                        name="password"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.formTextField}
                        type={showPassword ? 'text' : 'password'}
                        variant="filled"
                        fullWidth
                        size="small"                        
                        onChange={(e) => this.handleChange(e.target.value, "password")}                        
                        value={user.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              sx={{ marginRight: '0px'}}
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }/>
                {user.password && user.password.length <= 8 && <p className={styles.errorMsg}>{this.validateField("password")}</p>}
              </div>
              <div className={styles.formRowItem}>
                <label htmlFor="cpassword">Confirm Password</label>
                <InputBase                        
                        id="cpassword" 
                        name="cpassword"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.formTextField}
                        type={showCPassword ? 'text' : 'password'}
                        variant="filled"
                        fullWidth
                        size="small"                        
                        onChange={(e) => this.handleChange(e.target.value, "cpassword")}                        
                        value={user.confirmPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              sx={{ marginRight: '0px'}}
                              onClick={this.handleClickShowCPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        } />                
                {user.password && user.confirmPassword && user.confirmPassword !== user.password && <p className={styles.errorMsg}>{this.validateField("cpassword")}</p>}
              </div>
            </div>
            <div className={styles.formText}>
              <Checkbox 
                checked={user.isReceiveUpdates} 
                className={styles.registerFormCheckbox}
                onChange={(e) => { user.isReceiveUpdates =  e.target.checked; this.setState({user: user})}} 
                size="small" 
                sx={{
                color: "#2f125e",
                '&.Mui-checked': {
                  color: "#2f125e",
                },
              }} />
              Yes, I want to receive updates from Spaces by BrandLab360
            </div>
            <div className={styles.formText}>
              <Checkbox 
                checked={user.isAgreeTCaPP} 
                className={styles.registerFormCheckbox} 
                onChange={(e) => { user.isAgreeTCaPP =  e.target.checked; this.setState({user: user})}} 
                size="small" 
                sx={{
                color: "#2f125e",
                '&.Mui-checked': {
                  color: "#2f125e",
                },
              }} />
              I agree to the <a href="" target="_blank">Terms & Conditions</a> and <a href="" target="_blank">Privacy Policy</a>
            </div>
            <div className={this.isFormValid() && user.isAgreeTCaPP ? styles.formButton : styles.formButtonDisabled} onClick={()=> this.registerUser()}>Sign up</div>
            <div className={styles.formText}>Already have an account? <a href="/login"> Sign in</a></div>
          </div>
        </div>
        <div className={styles.logoContainer}>
          <div className={styles.bcgLogo} style={{backgroundImage: 'url("./images/logo/Logo.png")'}}></div>
          <div className={styles.rightCompanyName}>&copy; BrandLab360 2023</div>
        </div>        
      </div>;

    return <>
      {loading ?
        <div className='loader'>
          <CircularProgress color="secondary" />
        </div>
        : ""}
      {contents}
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={this.handleClose}>
        <Alert onClose={this.handleClose} severity={snackBarType} sx={{ width: '100%' }}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>;
  } 
  
  async registerUser() {
    if (this.isFormValid()) {

      this.setState({ loading: true });

      const response = await fetch('api/Auth/Register', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(this.state.user)
      });

      const data = await response.json();

      if (response.status != 200) {
        console.error('HTTP response code status->' + response.status + ' message->' + data);
        this.setState({ loading: false });
        this.setSnackBarMessage(true, true, data);
        return;
      }

      console.log(data);
      this.setState({ loading: false });
      this.setSnackBarMessage(true, false, "You have succesfully registered!");
      saveToken(data);
      window.location.href = '/';
    }
  }
}
