import { Component } from "react";
import styles from "./../CSSModules/MyStoreView.module.css";
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox  from "@mui/material/Checkbox";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center'
    },
}));

export class MyStoreView extends Component {

    constructor() {
        super();
        this.state = {
            name: "My Store",
            hide: false,            
            loading: true,           
            storeName: "",
            logoUrl: "",
            storeSizeId: 0,
            logoKey: "logoUrl",                       
            serverImage: "",
            storeThemeId: 0,
        };
    }

    
    componentDidMount() {
        this.setState({
            loading: false,
            storeName: this.props.unityUser.storeName,
            storeSizeId: this.props.unityUser.sizeId,
            storeThemeId: this.props.unityUser.themeId,
            logoUrl: !this.props.unityUser.logoUrl.includes("/Files/Logo") ? this.props.unityUser.logoUrl : "",
            serverImage: this.props.unityUser.logoUrl.includes("/Files/Logo") ? this.props.unityUser.logoUrl : ""
        });  
    }

    componentWillUnmount() {        
    }

    focusInput(e){
        window.unity.SendMessage('bloodbridge', "disablekeycapture", "");
    }

    blurLogoInput(e){
        window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");

        if(this.state.logoUrl) {           
            if(this.state.logoUrl !== this.props.unityUser.logoUrl) {
                this.updateUnityLogo(this.state.logoUrl);
            }
        }
    }

    blurNameInput() {
        window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");
        this.updateStoreName(this.state.storeName);
    }

    setStoreValue(value) {       
        this.setState({ storeName: value });
    }

    resetLogo() {
        this.setState({ logoUrl: "", serverImage: "" });  
    }

    setLogoValue(value) {
        this.setState({ logoUrl: value, serverImage: "" });
    }

    setStoreSize(storeSizeId) {
        if(storeSizeId === this.state.storeSizeId) {
            return ;
        }

        let user = this.props.unityUser;
        user.sizeId = storeSizeId;

        this.setState({storeSizeId: storeSizeId });
        this.props.onUnityUserDataChange(user);
    }

    setTheme(themeId) {
        if(themeId === this.state.storeThemeId) {
            return ;
        }

        let user = this.props.unityUser;
        user.themeId = themeId;

        this.setState({storeThemeId: themeId });
        this.props.onUnityUserDataChange(user);
    }

    getLogoFromPC() {
        document.getElementById("uploadlogo").click();
    }

    uploadLogo(e) {
        let file = document.getElementById("uploadlogo").files[0];
        if (file) {
            if (file.size > 10485760) {
                alert("File is too big!");
                filesValid = false;
                console.log("Files over 10mb size");
                return;
            }
            else {                
               this.uploadLogoToServer(file);
            }
        }
    }

    updateStoreName(name) {
        let user = this.props.unityUser;
        user.storeName = name;
        this.props.onUnityUserDataChange(user);  
    }

    updateUnityLogo(url) {
        let user = this.props.unityUser;
        user.logoUrl = url;

        this.props.onUnityUserDataChange(user);  
    }

    async uploadLogoToServer(file) {
        const form = new FormData();
        form.append("logoFile", file);

        const response = await fetch('api/account/uploadStoreLogo', {
            method: 'POST',
            body: form
        });

        const data = await response.json();

        if (response.status != 200) {
            console.error('HTTP response code != 200' + data.message);
            return;
        }
        else {            
            this.setState({ serverImage: data, logoUrl: "" });
            this.updateUnityLogo(data);
        }
    }

    renderThemes() {
        var { storeThemeId } = this.state;

        return (
            <div className={styles.storeThemeContainer}>
            <div className={styles.themeItem + ' ' + (storeThemeId === 0 ? styles.selectedTheme : '')} onClick={() => { this.setTheme(0) }}>
              <Checkbox name="storeTheme"
                checked={storeThemeId === 0}
                className={styles.themeCheckbox}
                size="small"
                sx={{
                  color: "#2f125e",
                  '&.Mui-checked': {
                    color: "#2f125e",
                  },
                }} />
  
              <div className={styles.themeImgContainer}>
                <img className={styles.themeImg}
                  src="./images/styles/shop_minimalistic_2.png"
                  alt="StoreThemeMini"
                  loading="lazy" />
              </div>
  
              <div className={styles.themeDescription}>
                <div className={styles.themeDescriptionTitle}>Minimalistic</div>
                <div>A modern range of material. The store contains clean lines and light bright colours.</div>
              </div>
  
            </div>
            <div className={styles.themeItem + ' ' + (storeThemeId === 1 ? styles.selectedTheme : '')} onClick={() => { this.setTheme(1) }}>
              <Checkbox name="storeThemeTrad"
                checked={storeThemeId === 1}
                className={styles.themeCheckbox}
                size="small"
                sx={{
                  color: "#2f125e",
                  '&.Mui-checked': {
                    color: "#2f125e",
                  },
                }} />
  
              <div className={styles.themeImgContainer}>
                <img className={styles.themeImg}
                  src="./images/styles/shop_traditional_2.png"
                  alt="StoreThemeTraditional"
                  loading="lazy" />
              </div>
  
              <div className={styles.themeDescription}>
                <div className={styles.themeDescriptionTitle}>Traditional</div>
                <div>A clasic range of materials such as wood. The store is neutral and warm colours.</div>
              </div>
  
            </div>
            <div className={styles.themeItem + ' ' + (storeThemeId === 2 ? styles.selectedTheme : '')} onClick={() => { this.setTheme(2) }}>
              <Checkbox name="storeThemeFut"
                checked={storeThemeId === 2}
                className={styles.themeCheckbox}
                size="small"
                sx={{
                  color: "#2f125e",
                  '&.Mui-checked': {
                    color: "#2f125e",
                  },
                }} />
  
              <div className={styles.themeImgContainer}>
                <img className={styles.themeImg}
                  src="./images/styles/shop_futuristic_2.png"
                  alt="StoreThemeFutur"
                  loading="lazy" />
              </div>
  
              <div className={styles.themeDescription}>
                <div className={styles.themeDescriptionTitle}>Futuristic</div>
                <div>A dark store with a pop of colour! Distinctively shaped furniture & modern materials.</div>
              </div>
  
            </div>
            <div className={styles.themeItem + ' ' + (storeThemeId === 3 ? styles.selectedTheme : '')} onClick={() => { this.setTheme(3) }}>
              <Checkbox name="storeThemeIn"
                checked={storeThemeId === 3}
                className={styles.themeCheckbox}
                size="small"
                sx={{
                  color: "#2f125e",
                  '&.Mui-checked': {
                    color: "#2f125e",
                  },
                }} />
  
              <div className={styles.themeImgContainer}>
                <img className={styles.themeImg}
                  src="./images/styles/shop_industrial_2.png"
                  alt="StoreThemeInd"
                  loading="lazy" />
              </div>
  
              <div className={styles.themeDescription}>
                <div className={styles.themeDescriptionTitle}>Industrial</div>
                <div>A beautiful combination of exposed brick, raw materials, and modern elements.</div>
              </div>
  
            </div>
          </div>
        );
    }

    renderMyStoreData() {
        var { storeName, logoUrl, serverImage } = this.state;
        var tutorialData = this.props.tutorialData;

        return (
            <div className={styles.myStoreScrollContainer}>
                <section className={styles.storeNameSection}>
                    <label>Store Name</label>
                    <div className={styles.searchContainer}>
                        <InputBase
                            placeholder="My Spaces Store"
                            sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                            className={styles.searchTextField}
                            type="text"
                            variant="filled"
                            fullWidth
                            size="small"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurNameInput(e)}
                            onChange={(e) => this.setStoreValue(e.target.value)}
                            value={storeName} />
                    </div>
                </section>
                <BootstrapTooltip
                        key={"mySore"}
                        open={tutorialData && tutorialData.isTutorialOpen && tutorialData.stepId === 1}
                        placement="right"
                        title={"Add your store name and logo."}>
                    <section className={styles.logoSection}>
                        <label>My Logo</label>
                        <div className={styles.storeLogoContainer}>
                            <div className={styles.storeLogo} onClick={() => this.getLogoFromPC()}>
                                ({logoUrl || serverImage
                                    ? (<img className={styles.imgItem}
                                        src={(logoUrl || serverImage) + "?t=" + new Date().getTime()}
                                        alt="storeLogo"
                                        loading="lazy" />)
                                    : (<img className={styles.imgItem}
                                        src="./images/icons/picture_upload_black.png"
                                        alt="storeLogo"
                                        loading="lazy" />)
                                })

                                <input hidden type="file" id="uploadlogo" accept="image/png, image/jpg, image/jpeg" name="logoFile" onChange={(e) => this.uploadLogo(e)}></input>
                            </div>

                            <div className={styles.urlContainer}>
                                <label className={styles.urlContainerLabel}> Or upload from a URL</label>
                                <div className={styles.urlInputContainer}>
                                    <InputBase
                                        placeholder="Enter image URL"
                                        sx={{ color: 'lightgray', fontFamily: "Cosmata", pt: "5px" }}
                                        className={styles.urlTextField}
                                        type="text"
                                        variant="filled"
                                        fullWidth
                                        size="small"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurLogoInput(e)}
                                        onChange={(e) => this.setLogoValue(e.target.value)}
                                        value={logoUrl} />

                                    <div className={styles.iconStoreContainer} onClick={() => this.resetLogo()}>
                                        <CloseIcon sx={{ color: "white", width: "15px", height: "15px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </BootstrapTooltip>
            </div>
        );
    }

    /*
     <section className={styles.sizeSection}>
                    <label>Store Size</label>
                    <div className={styles.storeSizeContainer + ' '+ (storeSizeId === 1 ? styles.selectedSize :'')} onClick={() => this.setStoreSize(1)}>
                        <Checkbox checked={storeSizeId === 1} className={styles.storeSizeCheckbox} size="small" sx={{
                            color: "#2f125e",
                            '&.Mui-checked': {
                                color: "#2f125e",
                            },
                        }} />

                        <div className={styles.storeSizeImgContainer}>
                            <img className={styles.storeSizeImg}
                                src="./images/icons/Store_Size_Small.png"
                                alt="StoreSizeSmall"
                                loading="lazy" />
                        </div>

                        <div className={styles.storeSizeDescription}>
                            <div className={styles.storeSizeDescriptionTitle}>Small Store</div>
                            <div>Ideal for up to 50 products</div>
                        </div>

                    </div>
                    <div className={styles.storeSizeContainer + ' '+(storeSizeId === 0 ? styles.selectedSize :'')} onClick={() => this.setStoreSize(0)}>
                        <Checkbox checked={storeSizeId === 0} className={styles.storeSizeCheckbox} size="small" sx={{
                            color: "#2f125e",
                            '&.Mui-checked': {
                                color: "#2f125e",
                            },
                        }}/>

                        <div className={styles.storeSizeImgContainer}>
                            <img className={styles.storeSizeImg}
                                src="./images/icons/Store_Size_Big.png"
                                alt="StoreSizeBig"
                                loading="lazy" />
                        </div>

                        <div className={styles.storeSizeDescription}>
                            <div className={styles.storeSizeDescriptionTitle}>Medium Store</div>
                            <div>Ideal for up to 100 products</div>
                        </div>

                    </div>
                </section>
                <section className={styles.storeThemeSection}>
                    <label>Store Theme</label>
                    {this.renderThemes()}
                </section>
     */

    render() {
        var { hide } = this.state;

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderMyStoreData();

        return (
            <div className={hide ? styles.mystoreHide : styles.mystore}>
                <h4 className={styles.mystoreTitle}>My Store</h4>
                {contents}
            </div>
        )
    }
}