import styles from "./CSSModules/TutorialStepper.module.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Component } from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';


const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6219D3",        
        fontSize: 16,
        borderRadius: 16,
        padding: '10px',
        textAlign: 'center'
    },
}));

export class TutorialStepper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "TutorialStepper",
            open: false,
            stepId: 0,
            disabled: true,
            isViewTooltipOpen: false,
            isStepTooltipOpen: false,
            isPublish: false,
            isStorePublished: false,
        };

    }

    componentDidMount() { 
        this.setState({ 
            disabled: this.props.disabled, 
            isPublish: this.props.isStorePublished,
            open: !this.props.isStorePublished,
            isViewTooltipOpen: !this.props.disabled && !this.props.isStorePublished,
            isStepTooltipOpen: !this.props.disabled && !this.props.isStorePublished,
            isStorePublished: this.props.isStorePublished,

        });

        this.props.onTutorialDataChange({stepId: 0, isTutorialOpen: !this.props.isStorePublished, isPublish: this.props.isStorePublished});
    }

    storePublished() {
        this.setState({isPublish: true,  open: false, isStorePublished: true, isViewTooltipOpen: false, isStepTooltipOpen: false});
        this.props.onTutorialDataChange({stepId: this.state.stepId, isTutorialOpen: false});
    }

    componentWillUnmount() { }

    toggleOpen = () => {        
        this.props.onTutorialDataChange({stepId: this.state.stepId, isTutorialOpen: !this.state.open});
        this.setState({isStepTooltipOpen: false, open: !this.state.open });
    }
   
    getStepClass(step) {
        var { stepId } = this.state;

        let cls = styles.step;
        if (stepId === step) {
            cls += ' '+styles.stepActive;
        }
        else if (stepId > step) {
            cls += ' '+styles.stepDone;
        }
        else if (step === 4) {
            cls+= ' '+styles.publishInactive;
        }
        else {
            cls += ' '+styles.stepInactive;
        }
        return cls;
    }

    setActiveStep = (stepId) => {
        if(stepId === 0 || this.state.stepId === stepId) {
            return;
        }

        this.setState({stepId: stepId, isStepTooltipOpen: true});
        this.props.onTutorialDataChange({stepId: stepId, isTutorialOpen: this.state.open, isPublish: this.state.isPublish});
    }

    onDropTutorialFinished() {
        console.log("Drop and Tutorial Finished");
        this.setState({isPublish: true});
    }

    renderStepper() {
        var { stepId, isStepTooltipOpen, isPublish, isStorePublished } = this.state;

        var steps = isStorePublished || isPublish ? 
        [
            'Store identity',
            'Theme and style',
            'Upload images',
            'Add to the store',
            'Publish'
        ]
        : [
            'Store identity',
            'Theme and style',
            'Upload images',
            'Add to the store'
        ];

        return <div className={styles.stepsContainer}>
            {
                steps.map((label, index) => {
                    if (stepId === 0 && index === 1) {
                        return <BootstrapTooltip
                            key={"t"+index}
                            onClose={() => this.setState({ isStepTooltipOpen: false })}
                            open={isStepTooltipOpen}
                            onMouseEnter={() => this.setState({ isStepTooltipOpen: true })}
                            onMouseLeave={() => this.setState({ isStepTooltipOpen: false })}
                            title="If you'd like to continue following the tutorial, click the next step.">
                            <div className={this.getStepClass(index)} key={index} onClick={() => this.setActiveStep(index)}>
                                {index < steps.length - 1 && <div className={styles.stepLine}></div>}
                                <div className={styles.stepCircle}>{index + 1}</div>
                                <div className={styles.stepLabel}>{label}</div>
                            </div>
                        </BootstrapTooltip>
                    }                    
                    else if (stepId > 0 && (stepId + 1) === index && index != 4) {
                        return <BootstrapTooltip
                            key={"t"+index}
                            onClose={() => this.setState({ isStepTooltipOpen: false })}
                            open={isStepTooltipOpen}
                            onMouseEnter={() => this.setState({ isStepTooltipOpen: true })}
                            onMouseLeave={() => this.setState({ isStepTooltipOpen: false })}
                            title={"Click the next step when you finished the step " + (index) + "!"}>
                            <div className={this.getStepClass(index)} key={index} onClick={() => this.setActiveStep(index)}>
                                {index < steps.length - 1 && <div className={styles.stepLine}></div>}
                                <div className={styles.stepCircle}>{index + 1}</div>
                                <div className={styles.stepLabel}>{label}</div>
                            </div>
                        </BootstrapTooltip>
                    }
                    else if (index === 4) {
                        return <div className={this.getStepClass(index)} key={index} onClick={() => this.setActiveStep(index)}>
                            {index < steps.length - 1 && <div className={styles.stepLine}></div>}
                            <div className={styles.stepCircle}>
                               {stepId !== index && <img className={styles.stepIcon} src="./images/icons/done_ic_purple.png" />}
                               {stepId === index && <img className={styles.stepIcon} src="./images/icons/done_ic_white.png" />}
                            </div>
                            <div className={styles.stepLabel}>{label}</div>
                        </div>
                    }
                    else {
                        return <div className={this.getStepClass(index)} key={index} onClick={() => this.setActiveStep(index)}>
                            {index < steps.length - 1 && <div className={styles.stepLine}></div>}
                            <div className={styles.stepCircle}>{index + 1}</div>
                            <div className={styles.stepLabel}>{label}</div>
                        </div>
                    }
                }
                )
            }
        </div>
    }
   

    handleMouseEnter=() => {        
        this.setState({ isViewTooltipOpen: true });
    }


    handleTooltipClose = () => {
        this.setState({ isViewTooltipOpen: false });
    } 


    render() {
        var { open, stepId, disabled, isViewTooltipOpen } = this.state;

        return (<ClickAwayListener onClickAway={() => this.setState({ isStepTooltipOpen: false, isViewTooltipOpen: false })}>
            <div className={styles.tutorialContainer}>
                {disabled ? <div className={styles.tutorialDisabledContainer} ></div> : ""}
                {open ?
                    <BootstrapTooltip
                        onClose={(this.handleTooltipClose)}
                        open={isViewTooltipOpen}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleTooltipClose}
                        key="tutorialView"
                        title="You can stop the tutorial at anytime with this button."
                        placement="bottom" >
                        <div className={styles.viewIconContainer} onClick={() => this.toggleOpen()}>
                            <img className={styles.viewIcon} src="./images/icons/view.png" />
                        </div>
                    </BootstrapTooltip>
                    :
                    <BootstrapTooltip
                        onClose={(this.handleTooltipClose)}
                        open={isViewTooltipOpen}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleTooltipClose}
                        key="tutorialViewHide"
                        title="You can start the tutorial at anytime with this button."
                        placement="bottom" >
                        <div className={styles.viewIconContainer} onClick={() => this.toggleOpen()}>
                            <img className={styles.viewIcon} src="./images/icons/view_hide.png" />
                        </div>
                    </BootstrapTooltip>
                }

                <div className={(open ? styles.tutorialStepper : styles.tutorialStepperClosed)}>
                    {this.renderStepper()}
                </div>
            </div>
        </ClickAwayListener>
        )
    }
}