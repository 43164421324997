import { Component } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import styles from "./../CSSModules/Popups/VideoChat.module.scss";
import { Checkbox, DialogContent, InputBase, Slide, TextareaAutosize } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AgoraRTM from 'agora-rtm-sdk';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6219D3',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6219D3",
      marginLeft: "0px !important",
      fontSize: 16,
      borderRadius: "16px",
      padding: "3px 10px"
    },
  }));

export const FeedbackDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {        
        backgroundColor: "#F4F6FC",
        color: "black",       
        maxHeight: "90%",
        borderRadius: "16px",
        overflowX: "hidden"
    }
}));

export function FeedbackDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ padding: "25px 0px", fontFamily: 'Cosmata Bold', textAlign: "center", backgroundColor: "#F4F6FC", color: "black" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


export class VideoChat extends Component {

    constructor() {
        super();
        this.state = {
            name: "VideoChat",
            open: false,
            link: "",
            shareLink: "",
            id: 0,
            isOwnerOnline: false,
            isRequestOwner: false,
            ownerId: -1,
        };

        this.channel = null;
    }

    componentDidMount() {
        const { id, storename } = this.props.urlParams;       
        this.setState({link: `${window.location.origin}/store/${id}/${storename}/`, id: id });        
        this.getAgoraUserData(id);
    }

    componentWillUnmount() {        

        if (this.channel) {            
            this.channel.leave();            
        }
    }

    async getAgoraUserData(storeId) {
        const response = await fetch(`api/auth/GetAgoraUserData/${storeId}`);
        const data = await response.json();
    
        if (response.status != 200) {
          console.error('HTTP response code = '+response.status +' message='+ data?.message);
          return;
        }
        else {
          console.log(data);      
          this.initSignaling(storeId, data.uid, data.token);
        }
      }
    
      async initSignaling(id, uid, token) {
        const appID = "6bce14764dc04670820efe44482703ed";
        const client = AgoraRTM.createInstance(appID);
    
        let options = {
          uid: uid,
          token: token
        };
    
        // Client Event listeners
        // Display messages from peer
        client.on('MessageFromPeer', function (message, peerId) {
          console.log("Message from: " + peerId + " Message: " + message);
        });
    
        // Display connection state changes
        client.on('ConnectionStateChanged', function (state, reason) {
          console.log("State changed To: " + state + " Reason: " + reason);
        });
    
        let channelName = "roomId-" + id;
        this.channel = client.createChannel(channelName);

        let that = this;
    
        this.channel.on('ChannelMessage', function (message, memberId) {
          console.log("Message received from: " + memberId + " Message: " + message);
          
          var data = JSON.parse(message.text);
          console.log(data);
          if(data.isOwner && data.isJoin) {
            that.setState({isOwnerOnline: true, ownerId: memberId});
          }
        });
    
        // Display channel member stats
        this.channel.on('MemberJoined', function (memberId) {
          console.log(memberId + " joined the channel");
        })
    
        // Display channel member stats
        this.channel.on('MemberLeft', function (memberId) {
          console.log(memberId + " left the channel");
          if(memberId === that.state.ownerId) {
            that.setState({isOwnerOnline: false, isRequestOwner: false, ownerId: -1});
          }
        });
    
        await client.login(options);
    
        await this.channel.join().then(() => {
          console.log("You have successfully joined channel " + this.channel.channelId);
        });
    
        //send message
        let msgObj = { isOwner: false, link: "", isJoin: true };
        let msg = JSON.stringify(msgObj);
    
        await this.channel.sendMessage({ text: msg });
      }

    focusInput = (e) => window.unity.SendMessage('bloodbridge', "disablekeycapture", "");

    blurInput = (e) => window.unity.SendMessage('bloodbridge', 'enablekeycapture', "");

    handleClickOpen = () => {
        let uuid = crypto.randomUUID();
        this.setState({ shareLink: this.state.link + uuid, open: true, });
    };

    handleClose = () => {
        this.setState({ open: false, shareLink: "", isRequestOwner: false });
    };
    
    async continue() {
        if (this.state.isRequestOwner) {           
            let msgObj = { isOwner: false, link: this.state.shareLink, isJoin: true };
            let msg = JSON.stringify(msgObj);

            await this.channel.sendMessage({ text: msg });
        }

        window.open(this.state.shareLink,"_self");
    }

    renderVideoChat() {
        var { open, shareLink, isOwnerOnline, isRequestOwner } = this.state;

        return <FeedbackDialog className="videoChatDialog"
            sx={{ '& .MuiPaper-root': { bgcolor: "#F4F6FC", borderRadius: "16px", maxWidth: "450px", padding: "0", } }}
            maxWidth={"450px"}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description">
            <FeedbackDialogTitle onClose={this.handleClose}></FeedbackDialogTitle>
            <DialogContent>
                <h5 className={styles.videoChatTitle}>Share the link to start a video meeting</h5>
                <div className={styles.videoChatDescription}>
                    You can invite others to your video meeting. Simply copy and paste the link and send it to anyone you’d like to join the chat.
                </div>
                <div className={styles.videoChatInputContainer}>
                    <img className={styles.videoChatLinkIcon} src="./images/icons/link_icon.png" />
                    <InputBase
                        placeholder="video html link"
                        sx={{ fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.videoChatUrlTextField}
                        type="text"
                        variant="filled"                        
                        disabled
                        size="small"
                        value={shareLink} />
                    <div className={styles.videoChatCopyButton} onClick={() => navigator.clipboard.writeText(shareLink)}>Copy</div>
                </div>

                <div className={isOwnerOnline ? styles.requestOwnerMeetingContainer : styles.requestOwnerMeetingContainerNotAllowed} 
                    onClick={()=>{isOwnerOnline ? this.setState({isRequestOwner: !isRequestOwner}) : false}}>

                    <img className={styles.requestOwnerIcon} src="./images/icons/headset_icon.png" />
                    <div className={styles.requestOwnerText}>Request meeting with store owner</div>
                    <Checkbox checked={isRequestOwner}
                        icon={<RadioButtonUncheckedOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        className={styles.requestOwnerCheckbox}
                        size="small"
                        sx={{
                            color: "#E6D9FA",
                            '&.Mui-checked': {
                                color: "#6219D3",
                            },
                        }} />
                </div>

                <div className={styles.actionVideoChatButtonContainer}>
                    <div className={styles.actionVideoChatButton} onClick={() => this.continue()}>Continue</div>
                </div>
            </DialogContent>
        </FeedbackDialog>;
    }

    render() {

        var {  } = this.state;

        return (<>            
            <BootstrapTooltip key="StartMeeting" title="Start Meeting" placement="top" >
                <div className={styles.videoChatIconContainer} onClick={() => this.handleClickOpen()}>
                    <img className={styles.videoChatIcon} src="./images/icons/video_chat_icon.png" />
                </div>
            </BootstrapTooltip>
            {this.renderVideoChat()}            
        </>
        )
    }
}