import { InputBase } from "@mui/material";
import { Component } from "react";

export class StoreNameStep extends Component {

    render() {

        let styles = this.props.styles;


        if (this.props.currentStep !== 1) {
            return null
        }
        return (
            <div className={styles.setupStoreStep}>
                <h2 className={styles.storeNameStepTitle}>What is the name of your store?</h2>
                <div className={styles.storeNameStepDescription}>You can change it anytime.</div>
                <div className={styles.storeNameStepInput}>
                    <InputBase
                        id="storeName"
                        name="storeName"
                        placeholder="Enter your store name"
                        sx={{ color: 'black', border: "1px solid grey", fontFamily: "Cosmata", pt: "5px" }}
                        className={styles.setupStoreTextField}
                        type="text"
                        variant="filled"
                        fullWidth
                        onChange={this.props.handleChange}
                        value={this.props.storeName} />
                    <div className={styles.setupStoreProceedButton} onClick={this.props.handleNext}>Next</div>
                </div>
            </div>
        );
    }
}